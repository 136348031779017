
/* .admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-thead th.ant-table-column-sort,
.admin-table .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: #E8E8E8;
	color: white;
} */
.admin-table .ant-table.ant-table-bordered > .ant-table-title,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> td,
.admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-tbody > tr > td {
	border-color: #b1afaf !important;
}
/* .admin-table .ant-table-thead > tr > th:first-child,
.admin-table .ant-table-tbody > tr > td:first-child {
	border-left: 1px solid#8D8D8D !important;
} */
.admin-table .ant-table {
	font-size: 16px;
	border: 1px solid #b1afaf;
}
.admin-table .ant-table-tbody > tr:nth-child(even) {
	background: #e8e8e8;
}
.admin-table td.ant-table-column-sort {
	background: inherit;
}
.admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-tbody > tr > td,
.admin-table .ant-table tfoot > tr > th,
.admin-table .ant-table tfoot > tr > td {
	padding: 6px 12px!important;
	font-size: 13px;
	color:black;
}
.admin-table .ant-table-tbody > tr > td:not(:last-child),
.admin-table .ant-table-thead > tr > th:not(:last-child){
	border-right: 1px solid #b1afaf !important;
}
.admin-table .ant-table-thead > tr > th{
	height: 50px;
}
.admin-table-width .ant-table-thead > tr > th{
	width: 220px !important;
}
.admin-table td{
	height: 40px;
}
.admin-table.ant-table-tbody>tr:last-child>td {
	border-bottom:none
}
.admin-table .ant-table-column-sorters {
	justify-content: start;
}
.admin-table .ant-table-column-sorter {
	margin-left: 10px;
}
.admin-table .ant-table-column-title {
	flex: initial;
}
td.ant-table-cell.td-right span{
	text-align: right;
	width: 55px;
	display: block;
	margin: auto;
}
td.ant-table-cell.center-star span{text-align: center !important;}
td.ant-table-cell.center-star .mytest-star{left: 25px !important;}
td.ant-table-cell.td-right-attemtedon span{
	text-align: right;
	width: 120px;
	display: block;
	margin: auto;
}

/* ----------  ------------ */
/* gap table */
.admin-gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
}

.admin-gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid var(--purple3);
}
.admin-gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid var(--purple3);
}
.admin-gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid var(--purple3);
}

.admin-gap-table th,
.admin-gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
}

.admin-gap-table th:first-child,
.admin-gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.admin-gap-table th:last-child,
.admin-gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}
.admin-gap-table td:not(:last-child) {
	border-right: 1px solid #e6e7e9;
}


/* ----------student activity modal------------ */
.stud-details{
	display: grid;
	grid-template-columns: 40% 60%;
	grid-column-gap: 12px;
}

/* ----------code generation------------ */
.button-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}
.button-wrapper button {
	min-width: 100px;
}
/* ----------feedback analysis------------ */
.red {
	color: red;
}
.green {
	color: green;
}