@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
body {
	height: 100%;
	margin: 0;
	font-family: "Roboto", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* common css */
html,
body {
	font-size: 14px;
}
:root {
	--darkblue: #00AAB5;
	--hoverblue: #00005c;
	--lightblue: #197bbd;
	--lightgrey: #8d8d8d;
	--graybg:#f2f4f5;
	--background-color: #f5f6f8;
	--errormsg: #ff0000;
	--divmarginbottom: 30px;
	/* Purple */
	--purple2: #A70050;
	--purple3:#F1667C;
	--purple4:#ff9aa9;
	--purple5:#fbccd3;
	--purple6:#C397ED;
	--purple7:#943684;
	--green1:#44A13F;
	--green2:#daecd9;
	--green3:#E6E89F;
	--greentests: #B9BD17;
	--olive:#767732;
	--red1: #ED1C24;
	--blue-3:#00B4CD;
	--orange-3:#ffc390;
	--orange-4:#F7943E;
	--purplehover: #e4f7fa;
	--badge2:"#b3e8f0";
	/* icons */
	--markforlater: #750D37;
}
/* a{
	text-decoration: underline !important;
} */
.ant-radio-wrapper, .ant-checkbox-wrapper,.ant-progress-text {
	color: #000000;
}
.mainHeading {
	color: #000000;
	font-size: clamp(1.2rem, calc(1.2rem + 0.8vw), 1.6rem);
	text-align: center;
	font-weight: normal;
}
.subHeading {
	color: black;
	font-size: clamp(1rem, calc(1rem + 0.6vw), 1.2rem);
	text-align: center;
	margin-bottom: 0;
}
.subHeading, .text-mute2 small{
	color: black;
}
.secondaryText {
	color: var(--lightgrey);
	font-size: 13px;
}
.remainingtext {
	color: black;
	font-size: clamp(1rem, calc(1rem + 0.6vw), 1.2rem);
	text-align: center;
	margin-bottom: 0;
}
/* .pc-navbar :matches(.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover) {
	color: black !important;
} */
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after {
	border: none;
}
.errorMsg {
	color: var(--errormsg) !important;
}
.greyMsg {
	color: var(--lightgrey) !important;
}
/* custom css */
.ant-btn.ant-btn-link,.ant-btn.ant-btn-link:hover, a, a:hover {
	color: var(--darkblue);
}
.ant-row {
	margin-right: auto !important;
	margin-left: auto !important;
}
.ant-btn.primary-blue:not(:disabled) {
	background-color: var(--darkblue);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.primary-blue:not(:disabled):hover,
.ant-btn.primary-blue:not(:disabled):focus,
.ant-btn.primary-blue:not(:disabled):active {
	background-color: var(--darkblue);
	color: white;
	border: none;
}
.ant-btn.btnDb:not(:disabled) {
	background-color: var(--purple2);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnDb:not(:disabled):hover,
.ant-btn.btnDb:not(:disabled):focus,
.ant-btn.btnDb:not(:disabled):active {
	background-color: var(--purple2);
	color: white;
	border: none;
}
.input-btn-purple,
.input-btn-purple:hover,
.input-btn-purple:focus,
.input-btn-purple:active {
	background-color: var(--purple2) !important;
	color: white;
	border-color: var(--purple2);
	width: 100px !important;
	cursor: pointer;
}
.already-logged-modal .ant-modal-footer{
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
.ant-btn.btnLb:not(:disabled) {
	background-color: var(--purple3);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLb:not(:disabled):hover,
.ant-btn.btnLb:not(:disabled):focus,
.ant-btn.btnLb:not(:disabled):active {
	background-color: var(--purple3);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLighter:not(:disabled) {
	background-color: var(--purple4);
	color: #000;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLighter:not(:disabled):hover,
.ant-btn.btnLighter:not(:disabled):focus,
.ant-btn.btnLighter:not(:disabled):active {
	background-color: var(--purple4);
	color: #000;
	border: none;
}
.ant-btn.btnQuit:not(:disabled) {
	background: var(--red1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnQuit:not(:disabled):hover,
.ant-btn.btnQuit:not(:disabled):focus,
.ant-btn.btnQuit:not(:disabled):active {
	background: var(--red1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btn-submit:not(:disabled) {
	background: var(--green1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btn-submit:not(:disabled):hover,
.ant-btn.btn-submit:not(:disabled):focus,
.ant-btn.btn-submit:not(:disabled):active {
	background: var(--green1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-menu-item-active,.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
	color: var(--purple3)!important;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after{
	border-color: var(--purple3)!important;
	color: var(--purple3) !important;
}
li.ant-menu-item-active,.ant-menu-submenu-open:hover::after,.ant-menu-submenu:hover::after,.ant-menu-submenu:hover, .ant-menu-submenu:active,.ant-menu-submenu-active {
	color: var(--purple3)!important;
	border-color: var(--purple3)!important;

}
.pc-navbar li:active, .pc-navbar li:focus, .pc-navbar li:hover, .pc-navbar li a:active, .pc-navbar li a:focus, .pc-navbar li a:hover, .ant-menu-submenu-title:hover,.ant-menu-submenu-title:active, .ant-menu-submenu-title:focus {
	color: var(--purple3)!important;

}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after{
	border-color: var(--purple3)!important;

}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
	color: var(--purple3)!important;

}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
	background-color: #ecddfc54;
} 
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
	color: var(--purple3);
}
/* padding & margin */
.m-auto{
	margin: auto !important;
}
.m-0 {
	margin: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.m-4 {
	margin: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}
.mr-1 {
	margin-right: 0.25rem !important;
}
.mb-1 {
	margin-bottom: 0.25rem !important;
}
.ml-1 {
	margin-left: 0.25rem !important;
}
.mx-1 {
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}
.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.ml-2 {
	margin-left: 0.5rem !important;
}
.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}
.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.my-3 {
	margin-bottom: 1rem !important;
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}
.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}
.mr-auto {
	margin-right: auto !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.ml-auto {
	margin-left: auto !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.my-auto {
	margin-bottom: auto !important;
	margin-top: auto !important;
}

.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}
.pr-1 {
	padding-right: 0.25rem !important;
}
.pb-1 {
	padding-bottom: 0.25rem !important;
}
.pl-1 {
	padding-left: 0.25rem !important;
}
.px-1 {
	padding-left: 0.25rem !important;
	padding-right: 0.25rem !important;
}
.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}
.pr-2 {
	padding-right: 0.5rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pl-2 {
	padding-left: 0.5rem !important;
}
.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}
.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.py-3 {
	padding-bottom: 1rem !important;
	padding-top: 1rem !important;
}
.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.mb-24 {
	margin-bottom: 24px;
}
/* negative */
.mt-neg-20px{
	margin-top: -20px;
}

/* Text alignment */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

/* content-alignment */
.d-block {
	display: block;
}
.d-flex {
	display: flex !important;
}
.flex-vertical-middle {
	display: flex;
	align-items: center;
}
.flex-middle {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.justify-between {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
}
.justify-end {
	display: flex !important;
	justify-content: flex-end;
}
.justify-end-middle {
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.d-inlineblock {
	display: inline-block;
}
.d-flex-wrap {
	display: flex;
	flex-wrap: wrap;
}
/* position */
.p-relative{
	position: relative;
}
/* wrap */
.nowrap {
	white-space: nowrap;
}
.break-spaces{
	white-space: break-spaces;
}

/* width & height*/
.h-100 {
	height: 100%;
}
.h-100vh{
	height: 100vh !important;
}
.h-86vh{
	height: 86vh !important;
}
.w-100 {
	width: 100%;
}
.w-80 {
	width: 80%;
}
.w-60 {
	width: 60%;
}
.w-auto{
	width: auto !important;
}
.h-auto{
	height: auto !important;
}
.min-width-70 {
	min-width: 70px;
}

.min-width-100 {
	min-width: 100px;
}

.min-width-200 {
	min-width: 200px;
}
.min-width-300 {
	min-width: 300px;
}
.min-ht-90 {
	min-height: 90px;
}
.fs-0 {
	font-size: 0;
}
.gap-40 {
	gap: 40px;
}

label:not(.ant-checkbox-wrapper, .ant-radio-wrapper, .ant-radio-button-wrapper) {
	font-weight: bold;
	text-transform: uppercase;
	white-space: break-spaces;
}
.label-default label {
	font-weight: unset;
	text-transform: unset;
}
h1 {
	font-weight: 700 !important;
}

.text-uppercase {
	text-transform: uppercase;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-capitalize {
	text-transform: capitalize;
}

.phone-w-100 .react-tel-input .form-control {
	width: 100% !important;
}

.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	border-color: transparent !important;
	background-color: var(--purple3) !important;
}

/* custom table css */
.ant-table .ant-table-tbody td:only-child {
	text-align: center;
}
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-thead th.ant-table-column-sort,
.customTable .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: var(--purple5);
	color: black;
}
.customTable .ant-table.ant-table-bordered > .ant-table-title,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> td,
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-tbody > tr > td {
	border-color: #8d8d8d !important;
}
.customTable .ant-table-thead > tr > th:first-child,
.customTable .ant-table-tbody > tr > td:first-child {
	border-left: 1px solid #8D8D8D !important;
}
.customTable .ant-table {
	font-size: 16px;
}
.customTable .ant-table-tbody > tr:nth-child(odd) {
	background: #eeeeee;
}
.custom-tutorial .ant-table-tbody > tr:nth-child(even) {
	background: #eeeeee;
}
.custom-tutorial .ant-table-tbody > tr:nth-child(odd){
	background: #fff;
}
.custom-tutorial th{
	background-color: #e8e8e8 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	word-break: keep-all !important;
}
.customTable td.ant-table-column-sort {
	background: inherit;
}
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-tbody > tr > td,
.customTable .ant-table tfoot > tr > th,
.customTable .ant-table tfoot > tr > td {
	padding: 12px !important;
}
.customTable .ant-table-column-sorters {
	justify-content: start;
}
.customTable .ant-table-column-sorter {
	margin-left: 10px;
}
.customTable .ant-table-column-title {
	flex: initial;
}
.icon-md {
	font-size: x-large !important;
}
.icon-lg {
	font-size: xx-large;
}
.icon-xlg {
	font-size: xxx-large;
}
.font-small{
	font-size: small;
}
.font-x-small{
	font-size: x-small;
}
.flash-ico {
	transform: rotate(90deg);
}
.note-ico {
	transform: scaleY(-1);
}
.cyan {
	color: #197bbd !important;
}
.olive {
	color: #767732 !important;
}
.orange {
	color: #f39c12 !important;
}
.purple {
	color: #78337f !important;
}
.text-mute {
	color: #dfe0eb !important;
}
.text-mute2 {
	/* color: var(--lightgrey) !important; */
	color: black;
}
.dark-tint {
	color: #2e2c2b;
}
.text-light {
	color: #ffffff !important;
}
.maroon {
	color: #750d37 !important;
}
.navy {
	color: var(--purple3);
}
.bg-secondary{
	background-color: var(--purple3) !important;
}
.yellow {
	color: #FFD814 !important;
}
.navy-2{
	color: var(--darkblue) !important;
}
.blue-2{
	color: #0000FF !important;
}
.red-1{
	color:var(--red1) !important;
}
.sky-bg {
	background-color: #a8cafd;
}
.sky-bg2 {
	background-color: var(--blue-3);
}
.leaf-bg {
	background-color: var(--green3);
}
.orange-bg {
	background-color: var(--orange-4);
}
.purple-bg {
	background-color: var(--purple5);
}
.purple-bg-dark {
	background-color: var(--purple7);
}
.bg-light {
	background-color: #ffffff;
}
.gray-bg{background-color: var(--graybg);}
.green-bg {
	background-color: var(--greentests);
	color: inherit;
}

.sky-bg, .sky-bg:hover, .sky-bg:focus {
	background-color: #a8cafd;
	color: inherit;
}
.sky-bg2, .sky-bg2:hover, .sky-bg2:focus {
	background-color: var(--blue-3);
	color: inherit;
}
.leaf-bg, .leaf-bg:hover, .leaf-bg:focus {
	background-color: var(--green3);
	color: inherit;
}
.orange-bg, .orange-bg:hover, .orange-bg:focus {
	background-color: var(--orange-4);
	color: inherit;
}
.purple-bg, .purple-bg:hover, .purple-bg:focus {
	background-color: var(--purple5);
	color: inherit;
}
.purple-bg-dark, .purple-bg-dark:hover, .purple-bg-dark:focus {
	background-color: var(--purple7);
	color: inherit;
}
.bg-light, .bg-light:hover, .bg-light:focus {
	background-color: #ffffff;
	color: inherit;
}
.ant-btn.green-bg, .ant-btn.green-bg:hover, .ant-btn.green-bg:focus {
	background-color: var(--greentests);
	color: inherit;
}

.place-center {
	display: grid !important;
	place-content: center;
}
.alert-items-center {
	align-items: center !important;
}
.white-text {
	color: white !important;
}
.navy-btn {
	border-color: var(--purple2) !important;
	background: var(--purple2) !important;
}
.navy-btn-select-test {
	border-color: var(--purple2) !important;
	background: var(--purple2) !important;
}
.lavender-btn{
	border-color: var(--purple6) !important;
	background: var(--purple6) !important;
}
.single-test-temaining{
	color: #ff0000;
}
.avatar {
	background-color: var(--purple3) !important;
	color: #ffffff;
}
.header-light {
	background: #fff !important;
}
.logo {
	float: left;
}
.slp-header {
	box-shadow: 0px 4px 4px 0px #00000040;
	z-index: 2;
}
/* .slp-header .ant-menu-title-content{font-weight: 300;} */
/* .slp-header .navy{font-weight: 400;} */
.footer-dark {
	background: #000000 !important;
	color: #ffffff !important;
}
.slp-footer {
	padding: 8px 50px !important;
}
.min-content-height {
	min-height: calc(100vh - 102px) !important;
}
.withp-footer-wrapper {
    min-height: calc(100vh - 102px - 61px)!important;
}

/* Mobile header */
.mobile-menu .ant-drawer-body {
	padding: 0;
}
.menu-btn.ant-btn-primary {
	background-color: var(--purple3);
	border: none;
}

/* gap table */
.ant-btn-link.table-link {
	padding: 0;
	color: inherit;
}
.gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
}

.gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid var(--purple3);

}
.gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid var(--purple3);
  background-color: var(--purplehover);
}

.gap-table th,
.gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
}

.gap-table th:first-child,
.gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.gap-table th:last-child,
.gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.pc-navbar .ant-menu-horizontal{display: none;}
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.bookname {
	line-height: 1;
	font-size: 2vmin;
	font-size: 14px;
}
.single-test-temaining{
	color: #ff0000 !important;
}
/* modal */
.modal-navy-btn, .modal-navy-btn:hover, .modal-navy-btn:focus, .modal-navy-btn:active {
	border-color: var(--purple3) !important;
	background: var(--purple3) !important;
	min-width: 100px;
	color: white;
}
/* xx */

/* disabled */
.ant-input[disabled] {
	color: black;
	background-color: #ebebeb;
}
/* xx */
.visibility-hidden{visibility: hidden;}
.form-item-mb-3 .ant-form-item{margin-bottom: 1rem !important;}
.pointer {
	cursor: pointer;
}
.default-cursor{
    cursor: default !important;
}
.qust-number {
	background-color: #ffec03;
	font-size: 22px;
	padding: 5PX;
	border-radius: 50px;
	margin-right: 10px;
	box-shadow: 3px 3px 4px lightgrey;
	font-weight: 500;
	min-width: 60px;
	display: inline-flex;
	height: 60px;
	align-items: center;
	justify-content: center;
}
.ml-10{
	margin-left: 10px;
}

/* body.decreaseFont .options p, body.decreaseFont .test-question, body.decreaseFont .test-question p {
	-moz-transform: scale(0.8, 0.8); 
    zoom: 0.8; 
    zoom: 80%;
		font-size: 10px;
} */
/* body.decreaseFont {
	font-size: 10px; 
	-moz-transform: scale(0.8, 0.8); 
    zoom: 80%;
}*/
body.decreaseFont .testSection .ant-card-body {
	-moz-transform: scale(0.8, 0.8); 
  zoom: 80%;
}
body.decreaseFontsmall .testSection .ant-card-body {
	-moz-transform: scale(0.7, 0.7); 
  zoom: 70%;
}
.hexagon.level0{background-color: #F7EFFF !important; color: white;}
.level0.hexagon:before {border-bottom-color: #F7EFFF !important;} 
.level0.hexagon:after{border-top-color: #F7EFFF !important;}

.hexagon.level1{background-color: var(--green2) !important; color: white;}
.level1.hexagon:before {border-bottom-color: var(--green2) !important;} 
.level1.hexagon:after{border-top-color: var(--green2) !important;}
.hexagon.level2{background-color: var(--badge2) !important; color: white;}
.level2.hexagon:before {border-bottom-color: var(--badge2) !important;} 
.level2.hexagon:after{border-top-color: var(--badge2) !important;}
.hexagon.level3{background-color: var(--orange-3) !important; color: white;}
.level3.hexagon:before {border-bottom-color: var(--orange-3) !important;} 
.level3.hexagon:after{border-top-color: var(--orange-3) !important;}
.hexagon.level4{background-color: var(--purple3) !important; color: white;}
.level4.hexagon:before {border-bottom-color: var(--purple3) !important;} 
.level4.hexagon:after{border-top-color: var(--purple3) !important;}
.hexagon.level5{background-color: var(--purple2) !important; color: white;}
.level5.hexagon:before {border-bottom-color: var(--purple2) !important;} 
.level5.hexagon:after{border-top-color: var(--purple2) !important;}

/* .flashicons .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--purple2);
}
.flashicons .ant-tabs-small>.ant-tabs-nav .ant-tabs-tab, .flashicons .ant-tabs-tab-btn:active, .flashicons .ant-tabs-tab-btn:focus, .flashicons .ant-tabs-tab-remove:active, .flashicons .ant-tabs-tab-remove:focus {
	color: var(--purple3);
} */ .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--purple2);
}
 .ant-tabs-small>.ant-tabs-nav .ant-tabs-tab,  .ant-tabs-tab-btn:active,  .ant-tabs-tab-btn:focus,  .ant-tabs-tab-remove:active,  .ant-tabs-tab-remove:focus {
	color: var(--purple3);
}
.ant-btn:focus, .ant-btn:hover{
	border-color: transparent !important;
}
.ant-radio-button-wrapper:hover{
	color: #000000 !important;
}
.ant-checkbox-checked .ant-checkbox-inner:not(.ant-checkbox-disabled .ant-checkbox-inner) {
	background-color: var(--purple3) !important;
	border-color: var(--purple3) !important;
}
.ant-radio-checked .ant-radio-inner{border-color: var(--purple3) !important;} 
.ant-radio-inner:after{background-color: var(--purple3) !important;}
.ant-input:hover, .ant-input:focus, .ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper:focus{border-color: #d9d9d9 !important; box-shadow: none !important;}
.ant-table{
	overflow-x: auto;
    overflow-y: hidden;
}
.pdfHeight{
	height: 75vh;
	overflow:auto;
}
.topic-chart-table table{font-size: x-small;}
.topic-chart-table td{
	padding: 5px !important;
	border: 1px solid rgba(0, 0, 0, .06);
}
.ant-tabs-tab:hover{
	color: unset !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
	background: var(--purple3);
}
.ant-table-filter-trigger.active{
	color: var(--purple3) !important;
}
.ant-table-filter-dropdown-btns .ant-btn-primary{
	border-color:var(--purple2) !important;
	background-color: var(--purple2) !important;
}
.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
	color: var(--purple3) !important;
}
.ant-menu-submenu-selected{
	color: var(--purple3) !important;
}
.ant-message-notice{
	margin-top: 8vh !important;
}
.ant-message-notice-content{
	border: 2px solid #FFEDAE;
    background-color: #FBF2D5;
}
.buorg-buttons{
	display: none !important;
}
.buorg-mainmsg{
	visibility: hidden;
}
.buorg-mainmsg::before{
	content: "This website is best viewed using latest versions of Chrome, Firefox, Safari, Microsoft Edge or a equivalent browser with a screen resolution of 1024 * 768 or higher. It appears that your browser is of older version. You may have trouble viewing our website or few features may not appear properly.";
	visibility: visible;
}
.buorg-moremsg{
	display: none;
}
.navy-check .ant-checkbox-input:focus+.ant-checkbox-inner, .navy-check.ant-checkbox-wrapper:hover .ant-checkbox-inner, .navy-check .ant-checkbox:hover .ant-checkbox-inner{
	border-color: #0000FF !important;
}
.navy-check .ant-checkbox-inner{
	border-color: #0000FF !important;
}
.navy-check .ant-checkbox-checked .ant-checkbox-inner:not(.navy-check .ant-checkbox-disabled .ant-checkbox-inner){
	border-color: #0000FF !important;
	background-color: #0000FF !important;
}
.d-none{display: none;}
.pg-not-found .ant-result-icon{
	margin-bottom: 0 !important;
}
.pg-not-found .ant-result-title{
	line-height: 1 !important;
}
.pg-not-found .ant-result-subtitle{
	font-size: large !important;
}
.maintain-txt-m{
	display: none;
}
.maintain-txt{
	display: block;
}
.min-h-full{min-height: calc(100vh - 102px);}

@media (max-width: 1199px) {
	.maintain-txt {
		display: none;
	}
	.maintain-txt-m{
		display: block;
	}
}
@media (min-width: 576px) {
	.d-sm-block {
		display: block;
	}
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
	.mobile-menu-div{display: none;}
	.pc-navbar .ant-menu-horizontal{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-width: 70%;
	}
	.bookname {
		width: 35%;
		position: absolute;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	.bookname {
		font-size: 1.17em;
	}
	.xl-float-right{
		float: right;
	}
	/* .fs-20, 
	.fs-20 span,
	.fs-20 p {
		font-size: 20px !important;
	} */
}
