.flashcard-progress .ant-progress-inner{border-radius: 0;}
.flash-tabs .ant-tabs-extra-content{width: 77%;}
.flash-tabs .ant-row .flash-filter{display: grid;place-items: end;}
.flash-tabs .ant-tabs-nav-wrap{justify-content: flex-end;}
.flash-tabs .ant-tabs-nav, .flash-tabs .ant-tabs-nav::before{border-bottom: none !important; margin-bottom: 0 !important;}
.flash-tabs .ant-tabs-ink-bar{display: none;}

.flash-tabs {padding-top: 1.2rem;}
.flashcard-progress {
  margin-top: 1.2rem;
  margin-bottom: 1.6rem;
}
.gap-table td.sr-no-col {
  text-align: right;
  margin: auto;
  padding: 0 20px !important;
  width: 60px;
}
/* Flash Card */
.flashcard-title {
  display: block;
  padding-top: 1.2rem;
}

.flashcards .ant-card{
  height: 365px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: thin;
}
/* #content{
  height: 365px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: thin;
} */

.flashcards .ant-card::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.flashcards .ant-card::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: #306DA5;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.flashcards .ant-card-head-title{
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-shadow: -1px 2.5px #00000040;
}
.flashcards .ant-card-head{border-bottom: none;}
.flashcards .ant-card-body{font-size: x-large; padding: 12px 40px !important;}

.flashcardsQnA .ant-card{height: 182.5px;cursor: default;}
.flashcardsQnA .ant-card-body{font-size: large;}
/* .flashcardsQnA .ant-card:nth-child(2){background: #F4F9FF;} */

/* center items in the flashcard */
.flashcard-items-center{display: grid !important;align-items: center;}
.flashcard-items-center .ant-card-body{text-align: center;}
.flashcard-items-center .ant-card-head{position: absolute; top: 0; width: 100%;}
.flashcard-items-center ul {
  text-align: left;
  width: 80%;
  margin: auto;
}
/* Flash list */
/* .flash-list .ant-card-head-title::before{
    content: 'Q';
    font-size: 24px;
    font-weight: 400;
    text-shadow: -1px 2.5px #00000040;
    margin-right: 5px;
} */
/* .flash-list .ant-card-head-title p:nth-child(2) {
  margin-left: 23px;
} */
.flash-list .flash-Q{
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
}
.flash-A {
  content: 'A';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
  line-height: normal;
}
/* .flash-list.flash-listQA .ant-card-head-title p:first-child:before{
  content: 'Q';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
} */
.flash-listQA .ant-card-small>.ant-card-head {
  background-color: var(--purplehover);
  border: 1px solid var(--purple3);
}
.flashcardsQnA .flashQnA-Q.ant-card {
  background-color: var(--purplehover);
}
.flash-listQA p {
  margin-bottom: 0;
}
/* .flash-answer:before {
  content: 'A';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
  line-height: normal;
} */
.flash-qa{
  display: flex;
  align-items: baseline;
 }
 .flash-list.flash-listQA .ant-card-body {
  margin-left: 0px;
}
.flash-list .ant-card-head-title{white-space: break-spaces;}

.flash-tabs .ant-tabs-tab .anticon{margin-right: 0;}

.flash-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
.flip-bookmrk-container{
  text-align: right;
  width: 89%;
}
.flip-bookmrk-container .flip-bookmark{
  position: absolute;
  top: 7px;
  z-index: 10000;
}
.mytest-star {
	position: absolute;
	top: 4px;
	left: 18px;
  font-size: 14px;
}
.mytest-star path{
  fill: #FFD814 !important;
}
.flashcardlist-card .ant-card-head{
  padding: 0px 24px;
}
.reviewflashcard{
  position: absolute;
  left: -10px;
  top: 50%;
  transform: rotate(270deg);
	color: rgb(163, 163, 163);
  font-style: italic;
	font-size: medium;
	border: none;
}
.reviewcard{
	color: rgb(163, 163, 163);
  font-style: italic;
	font-size: medium;
	border: none;
}
.reviewedListFlashcard{
  font-style: italic;
  color: rgb(163, 163, 163);
  font-size: small;
}
@media (min-width: 768px) {
 .flash-container {width: 750px;}
 .flip-bookmrk-container {width: 94%;}
}
@media screen and (min-width: 992px) {
 .flash-list .ant-card-body{margin-left: 22px;}
 .flash-container {width: 970px;}
}
@media (min-width: 1200px) {
 .flash-container {width: 768px;}
}