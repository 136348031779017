.faq-accordion.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--purplehover);
  box-shadow: rgb(19 30 41 / 7%) 0px 7px 12px 0px;
  color: #222;
  line-height: 32px;
}
.faq-card .ant-card-body{
  background-color: var(--purplehover);
  box-shadow: 0 7px 12px 0 rgb(19 30 41 / 7%);
  color: #222;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  padding: 12px 16px;
}
.faq-arrow.anticon {
  margin-right: 12px;
  font-size: 12px;
}
.arrow-rotate {
  transform: rotate(90deg) .24s;
}
@media (max-width: 992px) {
	.faq-accordion img{
    width: 100%;
    height: auto;
  }
}