.test-status{
	background-color: var(--background-color);
	min-height: calc( 100vh - 123px ); /* full height minus header and footer height */
	/* display: flex;
	align-items: center;
	justify-content: center; */
}
.main-title{
	font-size: 18px;
	text-align: center;
}
.test-status-content{
	max-width: clamp(70%, 900px, 95%);
	margin: auto;
	padding-top: 40px;
}
.statustimer-wrapper{
	font-size: 1.5rem;
	width: max-content;
	margin-left: auto !important;
	margin-top: -34px;
}
.status-container.ant-card, .status-container .ant-card-body{
	text-align: center;
}

.status-title{
	font-size: clamp(1.4rem, calc( 1.2vw + 1.4rem ), 1.8rem);
	font-size: 24px;
	margin-bottom: 10px;
}
.status-content p{
	font-size: clamp(0.9rem, calc( 1vw + 1rem ), 1.3rem);
	margin: 0;
	line-height: 35px;

}
.status-icon.anticon, .mfl-icon.anticon{
	font-size: 56px;
	color: white;
	background-color: var(--purple2);
	border-radius: 50%;
	padding: 10px;
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	margin: auto;
	margin-bottom: 10px;
}
.status-icon svg, .mfl-icon{
	height: 3rem;
}
.status-icon.anticon{
	background-color: var(--purple2);
}
.danger-icon.anticon {
	background-color: var(--errormsg);
}
.mfl-icon.anticon {
		background-color: #750d37;
}
.or{
	width: max-content;
	margin: 8px auto !important;
	position: relative;
	font-size: 16px !important;
}
.or:before{
	content: "";
	position: absolute;
	top: 46%;
	left: 30px;
	width: 46px;
	height: 2px;
	background-color: var(--purple2);
}
.or:after{
	content: "";
	position: absolute;
	top: 46%;
	right: 30px;
	width: 46px;
	height: 2px;
	background-color: var(--purple2);
}
.pausetime-wrapper, .completetime-wrapper {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	padding-top: 10px;
	min-height: 32px;
}
.pausetime-wrapper .ant-btn.timerIcon, .completetime-wrapper .ant-btn.timerIcon  {
	margin-left: -8px;
	height: 20px;
}
[ant-click-animating-without-extra-node='true']::after{display:none;}
/* share score */
.detail-score-wrapper{
	/* width: clamp(30%, calc( 1vw + 20rem ), 75%); */
	max-width: 380px;
	margin: auto;
	text-align: center;

}
.detail-section.ant-card p, .book-details.ant-card p{
	margin-bottom: 0;
}
.detail-section.ant-card{
 background-color: #C397ED;
 color: white;
	text-align: center;
	font-size: 1.5rem;
	position: relative;
}
.book-details.ant-card {
	background-color: #E4E893;
	color: #78337F;
	font-size: 1rem;
}
.book-details .ant-card-body {
	padding: 15px 24px 15px;
}
.checked.anticon{
	font-size: 56px;
	color: #C397ED;
	background-color: white;
	border-radius: 50%;
	padding: 10px;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	margin: auto;
}
.greetings{
	/* font-size: clamp(1rem, calc( 1vw + 1.4rem ), 2.2rem); */
	font-size: 36px;
}
.name{
	/* font-size: clamp(1rem, calc( 1vw + 1.4rem ), 1.6rem); */
	font-size: 32px;
}
.score{
	/* font-size: clamp(1rem, calc( 1vw + 1rem ), 1.3rem); */
	font-size: 18px;
	font-weight: 400;
}
.book-details p{
	/* font-size: clamp(1rem, calc( 1vw + 0.5rem ), 1.3rem); */
	font-size: 20px;
	text-align: center;
	line-height: 1.3;
	font-weight: 500;
}
.share-button-wrapper{
	position: absolute;
	right: -30px;
	bottom: 30px;
	width: max-content;
}
.share-button-wrapper .ant-btn{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 4px;
}

.share-button.ant-btn:not(:disabled), .share-button.ant-btn:not(:disabled):hover, .share-button.ant-btn:not(:disabled):focus, .share-button.ant-btn:not(:disabled):active{
	/* position: relative; */
	background-color: black;
	border: none;
	box-shadow: none;
	color: white;
	z-index: 2;
	width: 50px;
	height: 50px;
}
.transition .ant-btn .anticon{
	font-size: 20px;
	color: grey;
}
.share-button.ant-btn .anticon{
	font-size: 30px;
}
.transition .anticon{
	margin-left: 0 !important;
}
.transition{
	overflow: hidden;
	/* position: relative; */
	position: absolute;
	bottom: 60px;
	right: 6px;
	z-index: 1;
	transition: rotate(90deg);
	animation: mymove 1s;
}
.imgwidthunset .ant-image-img{
	width: unset;
}
@keyframes mymove {

	0%  {   width: 100%;
			height: 0px;
			top: 0px;
			bottom: 0px;
			right: 6px;
			left: 4px;
		}

	100% {  width:100%;
			top: -130px;
			bottom: 50px;
			right: 6px;
			left: 4px;
	}
  }
.result-btn.ant-btn, .result-btn.ant-btn:hover, .result-btn.ant-btn:focus, .result-btn.ant-btn:active{
	background-color: #9B51E0;
	color: white;
	border: none;
	margin: auto;
}
