.div{
    background-color: #e6e7e9;
}
/* gap table */
.gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
    padding: 1rem;
}
.gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid var(--purple3);
}

.gap-table th,
.gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
	padding: 5px;
  	text-align: left;
}

.gap-table th:first-child,
.gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.gap-table th:last-child,
.gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}
.div2{
	padding: 1.5rem !important;
}
.reg-table td {
	min-width: 38px; 
	text-align: center;
}
.reg-table th {
	text-align: left;
}
.table .ant-table-tbody>tr>td:not(:first-child){
	text-align: right;
}
.table{
	padding: 1rem !important;
}
/* .table2{
	padding: 1rem !important;
} */
.ant-table-summary>tr>td.last-element:last-child span{
	text-align: right;
	width: 24px ;
	display: block;
	margin: auto;
}
.table .ant-table-tbody>tr>td, 
.table .ant-table-thead>tr>th, 
.table .ant-table tfoot>tr>td, 
.table .ant-table tfoot>tr>th {
    position: relative;
    padding: 12px;
    overflow-wrap: break-word;
}
.table2 .ant-table-tbody>tr>td, 
.table2 .ant-table-thead>tr>th, 
.table2 .ant-table tfoot>tr>td, 
.table2 .ant-table tfoot>tr>th {
    position: relative;
    padding: 5px;
    overflow-wrap: break-word;
}
th:last-child.td-registartion{
	text-align: center;
}
td:last-child.td-registartion{
    text-align: right;
    display: block;
    padding-right: 70px !important;
}
td:last-child.td-registartion-role span{
    text-align: right;
    width: 25px;
    display: block;
    margin: auto;
}
td:last-child.td-total-registration span{
    text-align: right;
    width: 65px;
    display: block;
    margin: auto;
}
.label-width label{
	width: 100px !important;
}
.gap{
	gap: 6px !important;
}
.gap-40{
	gap: 40px !important;
}
.border{
	border: 1px solid #e6e7e9;
}
.gap-status{
	gap: 22px !important;
}
/* .ant-btn:focus, .ant-btn:hover .button {
    border-color: #e6e7e9
} */
.card.ant-card-small>.ant-card-head {
    min-height: 36px;
    font-size: 14px;
	padding: 0 24px;
}
.a-color .a:hover{
	color: #ffffff;
}
.react-qill-editor .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 0;
    height: 200px !important;
}
.admin-flashcard-items-center .ant-card-body{
	text-align: left !important;
	width: 50% !important;
	margin: auto !important;
	height: 300px !important;
}
.admin-flashcard-items-center .ant-card-head{position: absolute; top: 0; width: 100%;}
.green-btn {
	border-color: #b7e9b9!important;
	background: #b7eb8f !important ;
	color: black !important;
	/* &:hover {
		background-color: var(--primary-hover-color) !important;
		border-color: var(--primary-hover-color) !important;
	} */
}
/* .green-btn .ant-btn:hover {
    text-decoration: none;
    background: #b7eb8f;
	color: black;
} */
.edit-butoon{
	background-color: var(--purple3)!important;
	color: white!important;
	border-color: var(--purple3)!important;
}
.filterbutton{
	background-color:#e6e7e9!important;
	color: black!important;
	border-color: #e6e7e9!important;
}
/* .ant-btn:active, .ant-btn:focus, .ant-btn:hover .edit-butoon {
	background-color: var(--purple3)!important;
	color: white !important;
} */
/* .ant-btn:active, .ant-btn:focus, .ant-btn:hover .green-btn {
	background: #b7eb8f;
	color: black;
} */
.ant-card-flashcard .ant-card {
    width: 60%;
    margin: auto;
	height:520px !important;
}
.ant-card-flashcard .ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
    padding: 8px 0;
    font-size: 35px;
}
.card-height .ant-card {
    height: 450px !important;
}
.div-bottom{
	position: absolute;
    bottom: 0;
    padding: inherit;
    margin: auto;
    width: 90%;
}
.edit .ant-radio  {
    margin-right: 2px;
}
.modal-close-button .ant-modal-close-x {
    width: 30px;
    height: 24px;
    line-height: 30px;
    color: red;
}
.radio-button-lable .ant-checkbox-wrapper, .ant-progress-text, .ant-radio-wrapper {
    color:rgba(0,0,0,.85);
}
.bold-label label:not(.ant-radio-wrapper){
	font-weight: 500;
}
.label-width label{
	min-width: 162px;
	padding: 10px;
}
.card-padding .ant-card-small>.ant-card-body {
    padding: 1px;
}
.input-width .ant-input{
	width: 90.6%;
}
.ml-175px{
	margin-left: 175px;
}
.popover-ml-3 .ant-popover-content{
	margin-left:1rem !important;
}
.disable_faq_ans .ql-editor{
	background-color: #dddddd;
	cursor: no-drop;
}