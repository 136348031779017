
.admin-dashboard {
  background-color: #F2F4F5;
}
.reg-tab-outer {
  --period: #56AAF2;
  --country: #F5A752;
  --state: #77CEBB;
  --university: #766EDB;
  --role: #197BBD;
  color: white;
}
.reg-tab-row {
  margin: auto !important;
  margin-right: -20px !important;
}

.reg-tab {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  width: calc( 20% - 20px );
  min-height: 100px;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reg-tab-period {
  background-color: var(--period);
}
.reg-tab-country {
  background-color: var(--country);
}
.reg-tab-state {
  background-color: var(--state);
}
.reg-tab-university {
  background-color: var(--university);
}
.reg-tab-role {
  background-color: var(--role);
}
.slp-admin-card .ant-card{
	border-radius: 8px;
	overflow: hidden;
  height: 100%;
}
.slp-admin-card .ant-card-body{
  padding: 0;
  background: #FAFAFA;
  height: 100%;
}
.crd-arrow{
	position: absolute;
	right: 0;
	bottom: 0;
}
.progress-gap {
  gap: 20px;
}
.color-crd-loop .slp-menu-card:nth-child(4n+1) .anticon{
  color: var(--purple7);
}

.color-crd-loop .slp-menu-card:nth-child(4n+2) .anticon{
  color: var(--olive);
}

.color-crd-loop .slp-menu-card:nth-child(4n+3) .anticon{
  color: var(--orange-4);
}

.color-crd-loop .slp-menu-card:nth-child(4n+4) .anticon{
  color: var(--lightblue);
}
.card-purple {
  --cardlightpurple: #EDDDFC;
  --carddarkpurple: #8A4D92;
  background: var(--cardlightpurple);
}
.card-purple .anticon {
  color: var(--carddarkpurple);
}
.card-yellow{
  --cardlightyellow: #E7E89F;
  --carddarkyellow: #767732;
  background: var(--cardlightyellow);
}
.card-yellow .anticon {
  color: var(--carddarkyellow);
}
.card-orange{
  --cardlightorange: #FCEBDB;
  --carddarkorange: #F39C12;
  background: var(--cardlightorange);
}
.card-orange .q-icon {
  color: var(--carddarkorange);
}
.card-blue{
  --cardlightblue: #BFD9F1;
  --carddarkblue: #197BBD;
  background: var(--cardlightblue);
}
.card-blue .anticon {
  color: var(--carddarkblue);
}
.prog-per {
  color: rgb(164, 164, 164);
}
.hig-reg-table .ant-table-thead {
  display: none;
}