.login-content{
    background-image: url('../assets/abstract-bg.jpg');
      /* Full height */
    height: calc( 100vh - 64px - 38px ); /* 100vh - header height - footer height */
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.version-content{
    background-image: url('../assets/abstract-bg.jpg');
      /* Full height */
    height: calc( 100vh - 64px - 38px ); /* 100vh - header height - footer height */
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.signup-link {
    position: absolute;
    right: 0px;
    top: 8px;
}
.login-btn {
    width: 100%;
}
.gold-btn{
    color: #ffffff !important;
    border-color: #A70050 !important;
    background-color: #A70050 !important;
}
.gold-btn:hover, .gold-btn:focus{
    border-color: #F1667C !important;
    background-color: #F1667C !important;
}
.login-px { padding-right:1rem!important;padding-left:1rem!important; height: 100%; }
.login-content .ant-btn-link {
    color: var(--purple2);
}
/* Registration */
.upload-icon{font-size: xx-large;}
.card-gray{background: #F5F5F5 !important;}
.reg-avatar-uploader .ant-upload.ant-upload-select-picture-card{
    width: 200px;
    height: 217px;
}
.reg-bg{
    background-image: url('../assets/reg-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
/* Registration end */
.profile-checkbox .ant-radio {
    margin-right: 0;
    margin-left: 10px;
}
.profile-checkbox .ant-checkbox {
    margin-right: 0;
}
.terms-modal .ant-modal-header{
    background-color: var(--purple4);
}
.terms-modal .ant-modal-title, .terms-modal .ant-modal-close-x{
    color: #000;
}
.my-profile-modal .ant-modal-close{
    top: -10px !important;
}
.terms-modal .ant-modal-body{
    padding: 0 !important;
}
.book-col .ant-image-img {
    width: 70%;
    margin: auto;
}
@media screen and (min-width: 576px) {
    .book-col .ant-image-img {
        width: 40%;
    }  
}
@media screen and (min-width: 992px) {
    .login-input{
        height: 59px;
        font-size: large !important;
    }
    .login-input .ant-input{font-size: large !important;}
    .login-form{width: 469px;}
    .login-px { padding-right:3rem!important;padding-left:3rem!important; }
    .login-col{margin-top: 5%;}
    .gold-btn{width: 193px;}
    .reg-form-width{width: 645px;}
    .reg-form{
        display: grid !important;
        place-content: center;
    }
    .book-col .ant-image {
        width: 90%;
    }
}
@media screen and (min-width: 1200px) {.ml-135{margin-left: 135px;} .book-col .ant-image-img {width: 100%;}}
@media screen and (max-width: 1200px) {
    .login-content {
        height: auto;
        padding-top: 5%;
    }
}
@media screen and (max-width: 992px) {
    .book-col .ant-image {
        /* width: 80%; */
        width: clamp(80%, calc( 1vw + 20rem ), 100%);
    }
    .login-col{
        width: 100%;
    }

}
@media screen and (max-width: 575px) {
    .login-content {
        height: auto;
        padding-top: 10%;
    }
    .login-col{
        padding-top: 80px;
    }
    .signup-link {
        right: -12px;
        top: 30px;
    }
}