.button-container button:not(:last-child) {
	margin-right: 10px
}
.slp-card .ant-card{
	border-radius: 8px;
	overflow: hidden;
}
.slp-card .ant-card-body{padding: 0; background: #FAFAFA; height: 100%;}
.crd-arrow{
	position: absolute;
	right: 0;
	bottom: 0;
}
.peach-crd{background: var(--orange-3) !important;}
.smart-crd-section{
	display: flex;
	justify-content: center;
	align-items: center;
}
.smart-crd-section .ant-carousel{width: 90%;}
.smart-card{background: var(--purple5) !important;border-radius: 8px !important;}
.smart-card .ant-card-body{min-height: 203px;}
.sch-card{
	/* width: 190px; */
	/* min-height: 205px; */
	border-radius: 8px !important;
}
.glance-card {
	font-size: 14px;
}
.glance-card .ant-card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.glance-card .dash-title {
	padding-bottom: 20px;
	display: block;
}
.glance-data span {
	text-align: left;
	display: block;
	margin: auto;
}
.dash-title {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	font-weight: 500;
}
.sch-card .ant-card-head{padding: 0 15px;}
.sch-card .ant-card-head-title{padding: 10px 0;}
.sch-card .ant-card-body{
	padding-top: 0;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
}
.sch-card .ant-form-item{margin-bottom: 4px;}
.blue-btn{
	text-transform: uppercase;
	color: var(--purple3) !important;
	border-color: var(--purple5) !important;
	background: var(--purple5) !important;
	font-weight: 500 !important;
	font-size: small !important;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px !important;
  }

.streak{
	display: flex;
	align-items: center;
	height: 40px;
	border-radius: 8px;
	background: linear-gradient(90deg, #FFA800 -27.48%, var(--orange-3) 50%);
}
.lit{
	font-size: 5rem;
	/* background: linear-gradient(180deg, rgba(255, 0, 0, 0.85) 0%, #FFA800 100%); */
	color: #FF550A !important;
	position: relative;
	top: -17px;
	left: 7px;
}
.day-count{
	font-size: 25px;
	font-weight: 500;
	line-height: 13px;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.streak-days{
	position: absolute;
	left: 28px;
	top: 0px;
}
.int-3{left: 20px !important;}
.streak-days small{font-size: 9px;}
.badges-pts{
	display: flex;
	justify-content: space-between;
}
.font-22{font-size: 22px;}
.font-18{font-size: 18px;}
.font-16{font-size: 16px;}
.font-14{font-size: 14px;}
.btn-bottom-right{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
}
.gray-btn{
	border-color: #BFBFBF !important;
	color: #58554A !important;
}
.streak-middle{
	display: flex !important;
	align-items: center;
}
.progress-chart-crd{height: 100%;}
.progress-chart{
	width: 100%;
	height: 260px;
}
.progress-chart svg > g:last-child:not(:first-child) {
	text-transform: capitalize;
}
.progress-chart .google-visualization-tooltip {
	box-shadow: 2px 2px 4px lightgray;
	min-width: 170px;
}
.progress-chart div.google-visualization-tooltip > ul > li > span {
	line-height: normal;
	padding: 0;
	margin: 0;
}
 /* g *  {
	width: 20px;
	background-color: #FF550A !important;
} */
.feedback-form .ant-form-item-label{font-weight:700;}
.feedback-form {
	counter-reset: section;
}
.feedback-form .ant-form-item-label:not(:nth-child(2)):before{
	counter-increment: section;
	content:counter(section) ") ";
	font-weight: bold;
}
/* Coupon */
.coupon {
	border: 3px dotted #DFE0EB;
	width: 100%;
	border-radius: 4px;
}
.promo-container {background-color: #f1f1f1;}
.promo {
  background: #ccc;
  padding: 3px;
}
.smart-card-bg-img::before {
	content: "";
	background-image: url(../assets/smt-crd-bg.svg) !important;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.2;
}
.smart-card-bg-img{overflow: hidden;}
.smart-card-bg-img .ant-card-body{position: relative;}
/* progress on test */
.progress-chart svg g g g g rect {
	stroke-width: 0px;
  stroke: transparent;
	box-shadow: none;
}
.sm-text-btn{
	border-radius: 8px!important;
	font-weight: 500!important;
    font-size: small!important;
	display: flex;
    align-items: center;
}
.shadow-none{
	box-shadow:none;
}
.black-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #0E0E0F;
}
.dark-orange-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #CC5500;
}
.light-orange-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #FFC000;
} 
.blue-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #0045AA;
} 
.indigo-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #513BC0;
} 
.olive-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #808000;
} 
@media screen and (min-width: 992px) {
.smart-crd-section .ant-carousel{width: 80%;}
.streak-days{left: 42px;top: 40px;}
.int-3{left: 33px !important;}
}
