.note-card{border: 1px solid #FFEDAE !important;}
.note-card .ant-card-head{
    border-bottom: 2px solid #FFEDAE;
    background-color: #FBF2D5;
}
.note-card .ant-card-body{background-color: #FEF7E4;}
.note-card .ant-typography{
    font-weight: 500;
    color: black;
}
.note-card .ant-card-body {padding: 0 !important;}
.note-card-body{padding: 12px; min-height: 112px;}
.note-card-footer{
    border-top: 1px solid #FFEDAE;
    padding: 5px 12px;
}

/* INSTRUCTIONS */
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    float: right;
    margin-top: 6px;
}
.instructions .ant-btn.btnDb ,.instructions .ant-btn.btn-submit, .instructions .qust-title{
    min-width: 85px;
    height: 24px;
    font-size: 12px;
    padding: 4px;
}
.inst-time-block {
    display: inline-block;
}
.inst-time-block button, .inst-time-block button:hover, .inst-time-block button:focus {
    height: auto!important;
    transform: scale(0.8);
    color: #767732;
    padding: 12px;
    width: 130px;
    border: 1px solid #d9d9d9;
}
.inst-label {
    min-width: 130px;
    display: inline-block;
}
.instructions .ant-card-small>.ant-card-head {
    background-color: var(--purplehover);
}
.hourglass {
    color: #767732;
}
.instructions .ant-collapse-content>.ant-collapse-content-box p:only-child {
    margin-bottom: 0;
}
.instructions .icon-md {
    font-size: 18px;
}
.inst-btn-wrapper {
    position: absolute;
    right: 4px;
    top: 0;
}
.inst-side-header{
    position: absolute;
    top: 0;
}
.instructions .ant-card-head-title {
    overflow: inherit;
    white-space: normal;
}
/* feedback analysis */
.comment-popover.ant-popover{
    max-width: 500px;
}
.comment-popover .ant-popover-content {
    white-space: break-spaces;
}

.div-below-grid{
    margin-top: -35px;
}

@media screen and (max-width: 767px) {
    .inst-btn-wrapper {
        position: static;
        float: right;
    }
}