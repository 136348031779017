@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
body {
	height: 100%;
	margin: 0;
	font-family: "Roboto", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* common css */
html,
body {
	font-size: 14px;
}
:root {
	--darkblue: #00AAB5;
	--hoverblue: #00005c;
	--lightblue: #197bbd;
	--lightgrey: #8d8d8d;
	--graybg:#f2f4f5;
	--background-color: #f5f6f8;
	--errormsg: #ff0000;
	--divmarginbottom: 30px;
	/* Purple */
	--purple2: #A70050;
	--purple3:#F1667C;
	--purple4:#ff9aa9;
	--purple5:#fbccd3;
	--purple6:#C397ED;
	--purple7:#943684;
	--green1:#44A13F;
	--green2:#daecd9;
	--green3:#E6E89F;
	--greentests: #B9BD17;
	--olive:#767732;
	--red1: #ED1C24;
	--blue-3:#00B4CD;
	--orange-3:#ffc390;
	--orange-4:#F7943E;
	--purplehover: #e4f7fa;
	--badge2:"#b3e8f0";
	/* icons */
	--markforlater: #750D37;
}
/* a{
	text-decoration: underline !important;
} */
.ant-radio-wrapper, .ant-checkbox-wrapper,.ant-progress-text {
	color: #000000;
}
.mainHeading {
	color: #000000;
	font-size: clamp(1.2rem, calc(1.2rem + 0.8vw), 1.6rem);
	text-align: center;
	font-weight: normal;
}
.subHeading {
	color: black;
	font-size: clamp(1rem, calc(1rem + 0.6vw), 1.2rem);
	text-align: center;
	margin-bottom: 0;
}
.subHeading, .text-mute2 small{
	color: black;
}
.secondaryText {
	color: #8d8d8d;
	color: var(--lightgrey);
	font-size: 13px;
}
.remainingtext {
	color: black;
	font-size: clamp(1rem, calc(1rem + 0.6vw), 1.2rem);
	text-align: center;
	margin-bottom: 0;
}
/* .pc-navbar :matches(.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover) {
	color: black !important;
} */
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after {
	border: none;
}
.errorMsg {
	color: #ff0000 !important;
	color: var(--errormsg) !important;
}
.greyMsg {
	color: #8d8d8d !important;
	color: var(--lightgrey) !important;
}
/* custom css */
.ant-btn.ant-btn-link,.ant-btn.ant-btn-link:hover, a, a:hover {
	color: #00AAB5;
	color: var(--darkblue);
}
.ant-row {
	margin-right: auto !important;
	margin-left: auto !important;
}
.ant-btn.primary-blue:not(:disabled) {
	background-color: #00AAB5;
	background-color: var(--darkblue);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.primary-blue:not(:disabled):hover,
.ant-btn.primary-blue:not(:disabled):focus,
.ant-btn.primary-blue:not(:disabled):active {
	background-color: #00AAB5;
	background-color: var(--darkblue);
	color: white;
	border: none;
}
.ant-btn.btnDb:not(:disabled) {
	background-color: #A70050;
	background-color: var(--purple2);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnDb:not(:disabled):hover,
.ant-btn.btnDb:not(:disabled):focus,
.ant-btn.btnDb:not(:disabled):active {
	background-color: #A70050;
	background-color: var(--purple2);
	color: white;
	border: none;
}
.input-btn-purple,
.input-btn-purple:hover,
.input-btn-purple:focus,
.input-btn-purple:active {
	background-color: #A70050 !important;
	background-color: var(--purple2) !important;
	color: white;
	border-color: #A70050;
	border-color: var(--purple2);
	width: 100px !important;
	cursor: pointer;
}
.already-logged-modal .ant-modal-footer{
	display: flex;
	justify-content: flex-end;
	grid-gap: 10px;
	gap: 10px;
}
.ant-btn.btnLb:not(:disabled) {
	background-color: #F1667C;
	background-color: var(--purple3);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLb:not(:disabled):hover,
.ant-btn.btnLb:not(:disabled):focus,
.ant-btn.btnLb:not(:disabled):active {
	background-color: #F1667C;
	background-color: var(--purple3);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLighter:not(:disabled) {
	background-color: #ff9aa9;
	background-color: var(--purple4);
	color: #000;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnLighter:not(:disabled):hover,
.ant-btn.btnLighter:not(:disabled):focus,
.ant-btn.btnLighter:not(:disabled):active {
	background-color: #ff9aa9;
	background-color: var(--purple4);
	color: #000;
	border: none;
}
.ant-btn.btnQuit:not(:disabled) {
	background: #ED1C24;
	background: var(--red1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btnQuit:not(:disabled):hover,
.ant-btn.btnQuit:not(:disabled):focus,
.ant-btn.btnQuit:not(:disabled):active {
	background: #ED1C24;
	background: var(--red1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btn-submit:not(:disabled) {
	background: #44A13F;
	background: var(--green1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-btn.btn-submit:not(:disabled):hover,
.ant-btn.btn-submit:not(:disabled):focus,
.ant-btn.btn-submit:not(:disabled):active {
	background: #44A13F;
	background: var(--green1);
	color: white;
	border: none;
	--antd-wave-shadow-color: none;
}
.ant-menu-item-active,.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
	color: #F1667C!important;
	color: var(--purple3)!important;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after{
	border-color: #F1667C!important;
	border-color: var(--purple3)!important;
	color: #F1667C !important;
	color: var(--purple3) !important;
}
li.ant-menu-item-active,.ant-menu-submenu-open:hover::after,.ant-menu-submenu:hover::after,.ant-menu-submenu:hover, .ant-menu-submenu:active,.ant-menu-submenu-active {
	color: #F1667C!important;
	color: var(--purple3)!important;
	border-color: #F1667C!important;
	border-color: var(--purple3)!important;

}
.pc-navbar li:active, .pc-navbar li:focus, .pc-navbar li:hover, .pc-navbar li a:active, .pc-navbar li a:focus, .pc-navbar li a:hover, .ant-menu-submenu-title:hover,.ant-menu-submenu-title:active, .ant-menu-submenu-title:focus {
	color: #F1667C!important;
	color: var(--purple3)!important;

}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after{
	border-color: #F1667C!important;
	border-color: var(--purple3)!important;

}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
	color: #F1667C!important;
	color: var(--purple3)!important;

}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
	background-color: #ecddfc54;
} 
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
	color: #F1667C;
	color: var(--purple3);
}
/* padding & margin */
.m-auto{
	margin: auto !important;
}
.m-0 {
	margin: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.m-4 {
	margin: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}
.mr-1 {
	margin-right: 0.25rem !important;
}
.mb-1 {
	margin-bottom: 0.25rem !important;
}
.ml-1 {
	margin-left: 0.25rem !important;
}
.mx-1 {
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}
.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.ml-2 {
	margin-left: 0.5rem !important;
}
.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}
.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.my-3 {
	margin-bottom: 1rem !important;
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}
.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}
.mr-auto {
	margin-right: auto !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.ml-auto {
	margin-left: auto !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.my-auto {
	margin-bottom: auto !important;
	margin-top: auto !important;
}

.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}
.pr-1 {
	padding-right: 0.25rem !important;
}
.pb-1 {
	padding-bottom: 0.25rem !important;
}
.pl-1 {
	padding-left: 0.25rem !important;
}
.px-1 {
	padding-left: 0.25rem !important;
	padding-right: 0.25rem !important;
}
.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}
.pr-2 {
	padding-right: 0.5rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pl-2 {
	padding-left: 0.5rem !important;
}
.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}
.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.py-3 {
	padding-bottom: 1rem !important;
	padding-top: 1rem !important;
}
.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.mb-24 {
	margin-bottom: 24px;
}
/* negative */
.mt-neg-20px{
	margin-top: -20px;
}

/* Text alignment */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

/* content-alignment */
.d-block {
	display: block;
}
.d-flex {
	display: flex !important;
}
.flex-vertical-middle {
	display: flex;
	align-items: center;
}
.flex-middle {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.justify-between {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
}
.justify-end {
	display: flex !important;
	justify-content: flex-end;
}
.justify-end-middle {
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.d-inlineblock {
	display: inline-block;
}
.d-flex-wrap {
	display: flex;
	flex-wrap: wrap;
}
/* position */
.p-relative{
	position: relative;
}
/* wrap */
.nowrap {
	white-space: nowrap;
}
.break-spaces{
	white-space: break-spaces;
}

/* width & height*/
.h-100 {
	height: 100%;
}
.h-100vh{
	height: 100vh !important;
}
.h-86vh{
	height: 86vh !important;
}
.w-100 {
	width: 100%;
}
.w-80 {
	width: 80%;
}
.w-60 {
	width: 60%;
}
.w-auto{
	width: auto !important;
}
.h-auto{
	height: auto !important;
}
.min-width-70 {
	min-width: 70px;
}

.min-width-100 {
	min-width: 100px;
}

.min-width-200 {
	min-width: 200px;
}
.min-width-300 {
	min-width: 300px;
}
.min-ht-90 {
	min-height: 90px;
}
.fs-0 {
	font-size: 0;
}
.gap-40 {
	grid-gap: 40px;
	gap: 40px;
}

label:not(.ant-checkbox-wrapper, .ant-radio-wrapper, .ant-radio-button-wrapper) {
	font-weight: bold;
	text-transform: uppercase;
	white-space: break-spaces;
}
.label-default label {
	font-weight: unset;
	text-transform: unset;
}
h1 {
	font-weight: 700 !important;
}

.text-uppercase {
	text-transform: uppercase;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-capitalize {
	text-transform: capitalize;
}

.phone-w-100 .react-tel-input .form-control {
	width: 100% !important;
}

.ant-radio-group-solid
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	border-color: transparent !important;
	background-color: #F1667C !important;
	background-color: var(--purple3) !important;
}

/* custom table css */
.ant-table .ant-table-tbody td:only-child {
	text-align: center;
}
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-thead th.ant-table-column-sort,
.customTable .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: #fbccd3;
	background: var(--purple5);
	color: black;
}
.customTable .ant-table.ant-table-bordered > .ant-table-title,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tbody
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> th,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.customTable
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> td,
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-tbody > tr > td {
	border-color: #8d8d8d !important;
}
.customTable .ant-table-thead > tr > th:first-child,
.customTable .ant-table-tbody > tr > td:first-child {
	border-left: 1px solid #8D8D8D !important;
}
.customTable .ant-table {
	font-size: 16px;
}
.customTable .ant-table-tbody > tr:nth-child(odd) {
	background: #eeeeee;
}
.custom-tutorial .ant-table-tbody > tr:nth-child(even) {
	background: #eeeeee;
}
.custom-tutorial .ant-table-tbody > tr:nth-child(odd){
	background: #fff;
}
.custom-tutorial th{
	background-color: #e8e8e8 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	word-break: keep-all !important;
}
.customTable td.ant-table-column-sort {
	background: inherit;
}
.customTable .ant-table-thead > tr > th,
.customTable .ant-table-tbody > tr > td,
.customTable .ant-table tfoot > tr > th,
.customTable .ant-table tfoot > tr > td {
	padding: 12px !important;
}
.customTable .ant-table-column-sorters {
	justify-content: start;
}
.customTable .ant-table-column-sorter {
	margin-left: 10px;
}
.customTable .ant-table-column-title {
	flex: initial;
}
.icon-md {
	font-size: x-large !important;
}
.icon-lg {
	font-size: xx-large;
}
.icon-xlg {
	font-size: xxx-large;
}
.font-small{
	font-size: small;
}
.font-x-small{
	font-size: x-small;
}
.flash-ico {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}
.note-ico {
	-webkit-transform: scaleY(-1);
	        transform: scaleY(-1);
}
.cyan {
	color: #197bbd !important;
}
.olive {
	color: #767732 !important;
}
.orange {
	color: #f39c12 !important;
}
.purple {
	color: #78337f !important;
}
.text-mute {
	color: #dfe0eb !important;
}
.text-mute2 {
	/* color: var(--lightgrey) !important; */
	color: black;
}
.dark-tint {
	color: #2e2c2b;
}
.text-light {
	color: #ffffff !important;
}
.maroon {
	color: #750d37 !important;
}
.navy {
	color: #F1667C;
	color: var(--purple3);
}
.bg-secondary{
	background-color: #F1667C !important;
	background-color: var(--purple3) !important;
}
.yellow {
	color: #FFD814 !important;
}
.navy-2{
	color: #00AAB5 !important;
	color: var(--darkblue) !important;
}
.blue-2{
	color: #0000FF !important;
}
.red-1{
	color:#ED1C24 !important;
	color:var(--red1) !important;
}
.sky-bg {
	background-color: #a8cafd;
}
.sky-bg2 {
	background-color: #00B4CD;
	background-color: var(--blue-3);
}
.leaf-bg {
	background-color: #E6E89F;
	background-color: var(--green3);
}
.orange-bg {
	background-color: #F7943E;
	background-color: var(--orange-4);
}
.purple-bg {
	background-color: #fbccd3;
	background-color: var(--purple5);
}
.purple-bg-dark {
	background-color: #943684;
	background-color: var(--purple7);
}
.bg-light {
	background-color: #ffffff;
}
.gray-bg{background-color: #f2f4f5;background-color: var(--graybg);}
.green-bg {
	background-color: #B9BD17;
	background-color: var(--greentests);
	color: inherit;
}

.sky-bg, .sky-bg:hover, .sky-bg:focus {
	background-color: #a8cafd;
	color: inherit;
}
.sky-bg2, .sky-bg2:hover, .sky-bg2:focus {
	background-color: #00B4CD;
	background-color: var(--blue-3);
	color: inherit;
}
.leaf-bg, .leaf-bg:hover, .leaf-bg:focus {
	background-color: #E6E89F;
	background-color: var(--green3);
	color: inherit;
}
.orange-bg, .orange-bg:hover, .orange-bg:focus {
	background-color: #F7943E;
	background-color: var(--orange-4);
	color: inherit;
}
.purple-bg, .purple-bg:hover, .purple-bg:focus {
	background-color: #fbccd3;
	background-color: var(--purple5);
	color: inherit;
}
.purple-bg-dark, .purple-bg-dark:hover, .purple-bg-dark:focus {
	background-color: #943684;
	background-color: var(--purple7);
	color: inherit;
}
.bg-light, .bg-light:hover, .bg-light:focus {
	background-color: #ffffff;
	color: inherit;
}
.ant-btn.green-bg, .ant-btn.green-bg:hover, .ant-btn.green-bg:focus {
	background-color: #B9BD17;
	background-color: var(--greentests);
	color: inherit;
}

.place-center {
	display: grid !important;
	place-content: center;
}
.alert-items-center {
	align-items: center !important;
}
.white-text {
	color: white !important;
}
.navy-btn {
	border-color: #A70050 !important;
	border-color: var(--purple2) !important;
	background: #A70050 !important;
	background: var(--purple2) !important;
}
.navy-btn-select-test {
	border-color: #A70050 !important;
	border-color: var(--purple2) !important;
	background: #A70050 !important;
	background: var(--purple2) !important;
}
.lavender-btn{
	border-color: #C397ED !important;
	border-color: var(--purple6) !important;
	background: #C397ED !important;
	background: var(--purple6) !important;
}
.single-test-temaining{
	color: #ff0000;
}
.avatar {
	background-color: #F1667C !important;
	background-color: var(--purple3) !important;
	color: #ffffff;
}
.header-light {
	background: #fff !important;
}
.logo {
	float: left;
}
.slp-header {
	box-shadow: 0px 4px 4px 0px #00000040;
	z-index: 2;
}
/* .slp-header .ant-menu-title-content{font-weight: 300;} */
/* .slp-header .navy{font-weight: 400;} */
.footer-dark {
	background: #000000 !important;
	color: #ffffff !important;
}
.slp-footer {
	padding: 8px 50px !important;
}
.min-content-height {
	min-height: calc(100vh - 102px) !important;
}
.withp-footer-wrapper {
    min-height: calc(100vh - 102px - 61px)!important;
}

/* Mobile header */
.mobile-menu .ant-drawer-body {
	padding: 0;
}
.menu-btn.ant-btn-primary {
	background-color: #F1667C;
	background-color: var(--purple3);
	border: none;
}

/* gap table */
.ant-btn-link.table-link {
	padding: 0;
	color: inherit;
}
.gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
}

.gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid #F1667C;
	border-left: 1px solid var(--purple3);

}
.gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid #F1667C;
	border-right: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid #F1667C;
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid #F1667C;
	border-bottom: 1px solid var(--purple3);
  background-color: #e4f7fa;
  background-color: var(--purplehover);
}

.gap-table th,
.gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
}

.gap-table th:first-child,
.gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.gap-table th:last-child,
.gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.pc-navbar .ant-menu-horizontal{display: none;}
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.bookname {
	line-height: 1;
	font-size: 2vmin;
	font-size: 14px;
}
.single-test-temaining{
	color: #ff0000 !important;
}
/* modal */
.modal-navy-btn, .modal-navy-btn:hover, .modal-navy-btn:focus, .modal-navy-btn:active {
	border-color: #F1667C !important;
	border-color: var(--purple3) !important;
	background: #F1667C !important;
	background: var(--purple3) !important;
	min-width: 100px;
	color: white;
}
/* xx */

/* disabled */
.ant-input[disabled] {
	color: black;
	background-color: #ebebeb;
}
/* xx */
.visibility-hidden{visibility: hidden;}
.form-item-mb-3 .ant-form-item{margin-bottom: 1rem !important;}
.pointer {
	cursor: pointer;
}
.default-cursor{
    cursor: default !important;
}
.qust-number {
	background-color: #ffec03;
	font-size: 22px;
	padding: 5PX;
	border-radius: 50px;
	margin-right: 10px;
	box-shadow: 3px 3px 4px lightgrey;
	font-weight: 500;
	min-width: 60px;
	display: inline-flex;
	height: 60px;
	align-items: center;
	justify-content: center;
}
.ml-10{
	margin-left: 10px;
}

/* body.decreaseFont .options p, body.decreaseFont .test-question, body.decreaseFont .test-question p {
	-moz-transform: scale(0.8, 0.8); 
    zoom: 0.8; 
    zoom: 80%;
		font-size: 10px;
} */
/* body.decreaseFont {
	font-size: 10px; 
	-moz-transform: scale(0.8, 0.8); 
    zoom: 80%;
}*/
body.decreaseFont .testSection .ant-card-body {
	-moz-transform: scale(0.8, 0.8); 
  zoom: 80%;
}
body.decreaseFontsmall .testSection .ant-card-body {
	-moz-transform: scale(0.7, 0.7); 
  zoom: 70%;
}
.hexagon.level0{background-color: #F7EFFF !important; color: white;}
.level0.hexagon:before {border-bottom-color: #F7EFFF !important;} 
.level0.hexagon:after{border-top-color: #F7EFFF !important;}

.hexagon.level1{background-color: #daecd9 !important;background-color: var(--green2) !important; color: white;}
.level1.hexagon:before {border-bottom-color: #daecd9 !important;border-bottom-color: var(--green2) !important;} 
.level1.hexagon:after{border-top-color: #daecd9 !important;border-top-color: var(--green2) !important;}
.hexagon.level2{background-color: "#b3e8f0" !important;background-color: var(--badge2) !important; color: white;}
.level2.hexagon:before {border-bottom-color: "#b3e8f0" !important;border-bottom-color: var(--badge2) !important;} 
.level2.hexagon:after{border-top-color: "#b3e8f0" !important;border-top-color: var(--badge2) !important;}
.hexagon.level3{background-color: #ffc390 !important;background-color: var(--orange-3) !important; color: white;}
.level3.hexagon:before {border-bottom-color: #ffc390 !important;border-bottom-color: var(--orange-3) !important;} 
.level3.hexagon:after{border-top-color: #ffc390 !important;border-top-color: var(--orange-3) !important;}
.hexagon.level4{background-color: #F1667C !important;background-color: var(--purple3) !important; color: white;}
.level4.hexagon:before {border-bottom-color: #F1667C !important;border-bottom-color: var(--purple3) !important;} 
.level4.hexagon:after{border-top-color: #F1667C !important;border-top-color: var(--purple3) !important;}
.hexagon.level5{background-color: #A70050 !important;background-color: var(--purple2) !important; color: white;}
.level5.hexagon:before {border-bottom-color: #A70050 !important;border-bottom-color: var(--purple2) !important;} 
.level5.hexagon:after{border-top-color: #A70050 !important;border-top-color: var(--purple2) !important;}

/* .flashicons .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--purple2);
}
.flashicons .ant-tabs-small>.ant-tabs-nav .ant-tabs-tab, .flashicons .ant-tabs-tab-btn:active, .flashicons .ant-tabs-tab-btn:focus, .flashicons .ant-tabs-tab-remove:active, .flashicons .ant-tabs-tab-remove:focus {
	color: var(--purple3);
} */ .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #A70050;
	color: var(--purple2);
}
 .ant-tabs-small>.ant-tabs-nav .ant-tabs-tab,  .ant-tabs-tab-btn:active,  .ant-tabs-tab-btn:focus,  .ant-tabs-tab-remove:active,  .ant-tabs-tab-remove:focus {
	color: #F1667C;
	color: var(--purple3);
}
.ant-btn:focus, .ant-btn:hover{
	border-color: transparent !important;
}
.ant-radio-button-wrapper:hover{
	color: #000000 !important;
}
.ant-checkbox-checked .ant-checkbox-inner:not(.ant-checkbox-disabled .ant-checkbox-inner) {
	background-color: #F1667C !important;
	background-color: var(--purple3) !important;
	border-color: #F1667C !important;
	border-color: var(--purple3) !important;
}
.ant-radio-checked .ant-radio-inner{border-color: #F1667C !important;border-color: var(--purple3) !important;} 
.ant-radio-inner:after{background-color: #F1667C !important;background-color: var(--purple3) !important;}
.ant-input:hover, .ant-input:focus, .ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper:focus{border-color: #d9d9d9 !important; box-shadow: none !important;}
.ant-table{
	overflow-x: auto;
    overflow-y: hidden;
}
.pdfHeight{
	height: 75vh;
	overflow:auto;
}
.topic-chart-table table{font-size: x-small;}
.topic-chart-table td{
	padding: 5px !important;
	border: 1px solid rgba(0, 0, 0, .06);
}
.ant-tabs-tab:hover{
	color: unset !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
	background: #F1667C;
	background: var(--purple3);
}
.ant-table-filter-trigger.active{
	color: #F1667C !important;
	color: var(--purple3) !important;
}
.ant-table-filter-dropdown-btns .ant-btn-primary{
	border-color:#A70050 !important;
	border-color:var(--purple2) !important;
	background-color: #A70050 !important;
	background-color: var(--purple2) !important;
}
.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
	color: #F1667C !important;
	color: var(--purple3) !important;
}
.ant-menu-submenu-selected{
	color: #F1667C !important;
	color: var(--purple3) !important;
}
.ant-message-notice{
	margin-top: 8vh !important;
}
.ant-message-notice-content{
	border: 2px solid #FFEDAE;
    background-color: #FBF2D5;
}
.buorg-buttons{
	display: none !important;
}
.buorg-mainmsg{
	visibility: hidden;
}
.buorg-mainmsg::before{
	content: "This website is best viewed using latest versions of Chrome, Firefox, Safari, Microsoft Edge or a equivalent browser with a screen resolution of 1024 * 768 or higher. It appears that your browser is of older version. You may have trouble viewing our website or few features may not appear properly.";
	visibility: visible;
}
.buorg-moremsg{
	display: none;
}
.navy-check .ant-checkbox-input:focus+.ant-checkbox-inner, .navy-check.ant-checkbox-wrapper:hover .ant-checkbox-inner, .navy-check .ant-checkbox:hover .ant-checkbox-inner{
	border-color: #0000FF !important;
}
.navy-check .ant-checkbox-inner{
	border-color: #0000FF !important;
}
.navy-check .ant-checkbox-checked .ant-checkbox-inner:not(.navy-check .ant-checkbox-disabled .ant-checkbox-inner){
	border-color: #0000FF !important;
	background-color: #0000FF !important;
}
.d-none{display: none;}
.pg-not-found .ant-result-icon{
	margin-bottom: 0 !important;
}
.pg-not-found .ant-result-title{
	line-height: 1 !important;
}
.pg-not-found .ant-result-subtitle{
	font-size: large !important;
}
.maintain-txt-m{
	display: none;
}
.maintain-txt{
	display: block;
}
.min-h-full{min-height: calc(100vh - 102px);}

@media (max-width: 1199px) {
	.maintain-txt {
		display: none;
	}
	.maintain-txt-m{
		display: block;
	}
}
@media (min-width: 576px) {
	.d-sm-block {
		display: block;
	}
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
	.mobile-menu-div{display: none;}
	.pc-navbar .ant-menu-horizontal{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-width: 70%;
	}
	.bookname {
		width: 35%;
		position: absolute;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	.bookname {
		font-size: 1.17em;
	}
	.xl-float-right{
		float: right;
	}
	/* .fs-20, 
	.fs-20 span,
	.fs-20 p {
		font-size: 20px !important;
	} */
}

.login-content{
    background-image: url(../../static/media/abstract-bg.da40a27f.jpg);
      /* Full height */
    height: calc( 100vh - 64px - 38px ); /* 100vh - header height - footer height */
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.version-content{
    background-image: url(../../static/media/abstract-bg.da40a27f.jpg);
      /* Full height */
    height: calc( 100vh - 64px - 38px ); /* 100vh - header height - footer height */
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.signup-link {
    position: absolute;
    right: 0px;
    top: 8px;
}
.login-btn {
    width: 100%;
}
.gold-btn{
    color: #ffffff !important;
    border-color: #A70050 !important;
    background-color: #A70050 !important;
}
.gold-btn:hover, .gold-btn:focus{
    border-color: #F1667C !important;
    background-color: #F1667C !important;
}
.login-px { padding-right:1rem!important;padding-left:1rem!important; height: 100%; }
.login-content .ant-btn-link {
    color: var(--purple2);
}
/* Registration */
.upload-icon{font-size: xx-large;}
.card-gray{background: #F5F5F5 !important;}
.reg-avatar-uploader .ant-upload.ant-upload-select-picture-card{
    width: 200px;
    height: 217px;
}
.reg-bg{
    background-image: url(../../static/media/reg-bg.b6d3c6ee.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
/* Registration end */
.profile-checkbox .ant-radio {
    margin-right: 0;
    margin-left: 10px;
}
.profile-checkbox .ant-checkbox {
    margin-right: 0;
}
.terms-modal .ant-modal-header{
    background-color: var(--purple4);
}
.terms-modal .ant-modal-title, .terms-modal .ant-modal-close-x{
    color: #000;
}
.my-profile-modal .ant-modal-close{
    top: -10px !important;
}
.terms-modal .ant-modal-body{
    padding: 0 !important;
}
.book-col .ant-image-img {
    width: 70%;
    margin: auto;
}
@media screen and (min-width: 576px) {
    .book-col .ant-image-img {
        width: 40%;
    }  
}
@media screen and (min-width: 992px) {
    .login-input{
        height: 59px;
        font-size: large !important;
    }
    .login-input .ant-input{font-size: large !important;}
    .login-form{width: 469px;}
    .login-px { padding-right:3rem!important;padding-left:3rem!important; }
    .login-col{margin-top: 5%;}
    .gold-btn{width: 193px;}
    .reg-form-width{width: 645px;}
    .reg-form{
        display: grid !important;
        place-content: center;
    }
    .book-col .ant-image {
        width: 90%;
    }
}
@media screen and (min-width: 1200px) {.ml-135{margin-left: 135px;} .book-col .ant-image-img {width: 100%;}}
@media screen and (max-width: 1200px) {
    .login-content {
        height: auto;
        padding-top: 5%;
    }
}
@media screen and (max-width: 992px) {
    .book-col .ant-image {
        /* width: 80%; */
        width: clamp(80%, calc( 1vw + 20rem ), 100%);
    }
    .login-col{
        width: 100%;
    }

}
@media screen and (max-width: 575px) {
    .login-content {
        height: auto;
        padding-top: 10%;
    }
    .login-col{
        padding-top: 80px;
    }
    .signup-link {
        right: -12px;
        top: 30px;
    }
}
.test-status{
	background-color: var(--background-color);
	min-height: calc( 100vh - 123px ); /* full height minus header and footer height */
	/* display: flex;
	align-items: center;
	justify-content: center; */
}
.main-title{
	font-size: 18px;
	text-align: center;
}
.test-status-content{
	max-width: clamp(70%, 900px, 95%);
	margin: auto;
	padding-top: 40px;
}
.statustimer-wrapper{
	font-size: 1.5rem;
	width: -webkit-max-content;
	width: max-content;
	margin-left: auto !important;
	margin-top: -34px;
}
.status-container.ant-card, .status-container .ant-card-body{
	text-align: center;
}

.status-title{
	font-size: clamp(1.4rem, calc( 1.2vw + 1.4rem ), 1.8rem);
	font-size: 24px;
	margin-bottom: 10px;
}
.status-content p{
	font-size: clamp(0.9rem, calc( 1vw + 1rem ), 1.3rem);
	margin: 0;
	line-height: 35px;

}
.status-icon.anticon, .mfl-icon.anticon{
	font-size: 56px;
	color: white;
	background-color: var(--purple2);
	border-radius: 50%;
	padding: 10px;
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	margin: auto;
	margin-bottom: 10px;
}
.status-icon svg, .mfl-icon{
	height: 3rem;
}
.status-icon.anticon{
	background-color: var(--purple2);
}
.danger-icon.anticon {
	background-color: var(--errormsg);
}
.mfl-icon.anticon {
		background-color: #750d37;
}
.or{
	width: -webkit-max-content;
	width: max-content;
	margin: 8px auto !important;
	position: relative;
	font-size: 16px !important;
}
.or:before{
	content: "";
	position: absolute;
	top: 46%;
	left: 30px;
	width: 46px;
	height: 2px;
	background-color: var(--purple2);
}
.or:after{
	content: "";
	position: absolute;
	top: 46%;
	right: 30px;
	width: 46px;
	height: 2px;
	background-color: var(--purple2);
}
.pausetime-wrapper, .completetime-wrapper {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	padding-top: 10px;
	min-height: 32px;
}
.pausetime-wrapper .ant-btn.timerIcon, .completetime-wrapper .ant-btn.timerIcon  {
	margin-left: -8px;
	height: 20px;
}
[ant-click-animating-without-extra-node='true']::after{display:none;}
/* share score */
.detail-score-wrapper{
	/* width: clamp(30%, calc( 1vw + 20rem ), 75%); */
	max-width: 380px;
	margin: auto;
	text-align: center;

}
.detail-section.ant-card p, .book-details.ant-card p{
	margin-bottom: 0;
}
.detail-section.ant-card{
 background-color: #C397ED;
 color: white;
	text-align: center;
	font-size: 1.5rem;
	position: relative;
}
.book-details.ant-card {
	background-color: #E4E893;
	color: #78337F;
	font-size: 1rem;
}
.book-details .ant-card-body {
	padding: 15px 24px 15px;
}
.checked.anticon{
	font-size: 56px;
	color: #C397ED;
	background-color: white;
	border-radius: 50%;
	padding: 10px;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	margin: auto;
}
.greetings{
	/* font-size: clamp(1rem, calc( 1vw + 1.4rem ), 2.2rem); */
	font-size: 36px;
}
.name{
	/* font-size: clamp(1rem, calc( 1vw + 1.4rem ), 1.6rem); */
	font-size: 32px;
}
.score{
	/* font-size: clamp(1rem, calc( 1vw + 1rem ), 1.3rem); */
	font-size: 18px;
	font-weight: 400;
}
.book-details p{
	/* font-size: clamp(1rem, calc( 1vw + 0.5rem ), 1.3rem); */
	font-size: 20px;
	text-align: center;
	line-height: 1.3;
	font-weight: 500;
}
.share-button-wrapper{
	position: absolute;
	right: -30px;
	bottom: 30px;
	width: -webkit-max-content;
	width: max-content;
}
.share-button-wrapper .ant-btn{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 4px;
}

.share-button.ant-btn:not(:disabled), .share-button.ant-btn:not(:disabled):hover, .share-button.ant-btn:not(:disabled):focus, .share-button.ant-btn:not(:disabled):active{
	/* position: relative; */
	background-color: black;
	border: none;
	box-shadow: none;
	color: white;
	z-index: 2;
	width: 50px;
	height: 50px;
}
.transition .ant-btn .anticon{
	font-size: 20px;
	color: grey;
}
.share-button.ant-btn .anticon{
	font-size: 30px;
}
.transition .anticon{
	margin-left: 0 !important;
}
.transition{
	overflow: hidden;
	/* position: relative; */
	position: absolute;
	bottom: 60px;
	right: 6px;
	z-index: 1;
	transition: rotate(90deg);
	-webkit-animation: mymove 1s;
	        animation: mymove 1s;
}
.imgwidthunset .ant-image-img{
	width: unset;
}
@-webkit-keyframes mymove {

	0%  {   width: 100%;
			height: 0px;
			top: 0px;
			bottom: 0px;
			right: 6px;
			left: 4px;
		}

	100% {  width:100%;
			top: -130px;
			bottom: 50px;
			right: 6px;
			left: 4px;
	}
  }
@keyframes mymove {

	0%  {   width: 100%;
			height: 0px;
			top: 0px;
			bottom: 0px;
			right: 6px;
			left: 4px;
		}

	100% {  width:100%;
			top: -130px;
			bottom: 50px;
			right: 6px;
			left: 4px;
	}
  }
.result-btn.ant-btn, .result-btn.ant-btn:hover, .result-btn.ant-btn:focus, .result-btn.ant-btn:active{
	background-color: #9B51E0;
	color: white;
	border: none;
	margin: auto;
}

/* category list */
.category-list-container {
	font-size: clamp(0.8rem, calc(1vw + 0.9rem), 1.4rem);
}
.categories-list {
	background-color: var(--purple5);
	color: #000000;
	text-align: center;
	padding: 10px 26px;
	min-height: 80px;
}
.categories-list .anticon {
	float: left;
	/* position: absolute;
	left: 30px; */
}
.categories-list svg {
	height: clamp(2rem, 1rem + 1.4vw, 3rem);
	width: auto;
}
.categories-list h1{
	color: white;
	font-weight: normal !important;
}
.ant-btn.categories-list {
	background-color: var(--purple5);
	border: none;
	color: #000000;
	text-align: center;
	padding: 10px 30px;
	min-height: 70px;
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.5rem);
}
.ant-btn.categories-list:hover,
.ant-btn.categories-list:focus {
	background-color: var(--purple5);
	color: #000000;
	box-shadow: 0px 0px 12px grey;
}
.categoryDetails button {
	font-size: inherit;
}
.categoryDetails button, .categoryDetails button:hover, .categoryDetails button:focus {
	background-color: inherit;
}
/* select Tutorials */
.ant-row.select-tut-outer {
	max-width: 1400px;
	margin-top: 60px !important;
	padding-bottom: 40px;
}
.border-bottom {
	border-bottom: 1px solid #000000;
}
/* categoryTutorial */
.category-tutorial-container {
	background-color: var(--background-color);
}
.test-drawer .ant-drawer-body{
	padding: 0 !important;
}
.test-drawer .grey-custom-table .ant-table {
	font-size: 1rem;
}
/* .test-drawer .grey-custom-table .ant-table tr th {
	display: none;
} */
.test-drawer .test-icon-container .anticon,
.test-drawer .test-question-details .anticon,
.test-icon-container .anticon,
.test-question-details .anticon {
	font-size: clamp(1.2rem, calc(1.2rem + 1vw), 1.7rem);
}
.test-drawer .test-icon-container img,
.test-drawer .test-question-details img,
.test-icon-container img,
.test-question-details img  {
	/* width: clamp(0.6rem, calc(1vw + 0.6rem), 1.5rem); */
	height: clamp(1.2rem, calc(1.2rem + 1vw), 1.7rem);
	width: auto;
}
.test-drawer .grey-custom-table .ant-table td .ant-btn-link {
	padding: 0;
}
.test-question-details {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.5rem);
	line-height: normal;
}
.options {
	padding: 14px 30px !important;
	border: 2px solid #D9D9D9;
	border-radius: 50px;
	width: 100%;
	align-items: center;
}
.options .ant-radio, .options .ant-checkbox {
	margin-top: -6px;
}
.options span:not(.ant-radio, .ant-checkbox){
	/* font-size: clamp(1rem, calc(1rem + 0.8vw), 1.2rem); */
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.4rem);
	font-family: inherit !important;
}
label.ant-radio-wrapper.ant-radio-wrapper-checked.options {
	color: var(--purple3);
}
.options p {
	margin-bottom: 0;
	line-height: normal;
}
.test-question p, .test-question {
	margin-bottom: 0;
}

.test-btn-container .ant-btn.btnDb:disabled, 
.test-btn-container .ant-btn.btnDb:disabled:active, 
.test-btn-container .ant-btn.btnDb:disabled:focus, 
.test-btn-container .ant-btn.btnDb:disabled:hover{
	pointer-events: none!important;
	background-color: var(--purple2);
	color: white;
}
.flex-direction-col{
	flex-direction: column;
}
.test-question,
.test-case-study {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.4rem);
	line-height: 1.4;
}
.test-options .options:hover,
.test-options .options:focus {
	border: 2px solid var(--purple3);
	color: var(--purple3);
}
.test-question > div {
	font-family: inherit !important;
}
.test-question table {
	margin-top: 20px;
	width: 80% !important;
	font-family: inherit;
	margin: auto;
}
.test-question table td p {
	margin-bottom: 8px;
}
.qust-title {
	background: var(--purple3);
	color: white;
	padding: 0 6px 3px;
	font-size: 20px;
	border: none;
}
.qust-title:hover,.qust-title:focus, .qust-title:active {
	background: var(--purple3)  !important;
	color: white !important;
	padding: 0 6px 3px;
	font-size: 20px;
}
.cat-title {
	color: var(--purple3)
}
.highlight.anticon {
	color: #00A96C;
}
.strike.anticon {
	color: #333333;
}
.flag.anticon {
	color: #750D37;
}
.flag.anticon svg{
	fill: #750D37;
}
.flag.anticon svg path{
	stroke: #750D37;
}
.explanation .ant-card-head,
.explanation .ant-card-body {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.3rem);
	line-height: 1.4;
}
.explanation .ant-card-body p {
	margin-bottom: 0;
}
.next.anticon,
.prev.anticon,
.close.anticon {
	font-size: 22px;
}
.next.anticon,
.prev.anticon {
	color: #767732;
}
.close.anticon {
	color: #EB5757
}
.test-footer {
	width: -webkit-max-content;
	width: max-content;
	margin-left: auto;
}
.test-options .ant-radio-checked .ant-radio-inner,
.test-options .ant-radio-wrapper:hover .ant-radio,
.test-options .ant-radio:hover .ant-radio-inner,
.test-options .ant-radio-input:focus+.ant-radio-inner {
	border-color: var(--purple3) !important;
	box-shadow: none !important;
}
.test-options .ant-radio-wrapper-checked {
	border-color: var(--purple3) !important;
	box-shadow: none !important;
}
.test-options .ant-radio-inner::after {
	background: var(--purple3) !important;
}
.test-options .ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--purple3);
	border-color: var(--purple3);
}
.test-options .ant-checkbox-checked:after {
	border-color: var(--purple3);
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.options {
	color: var(--purple3);
	border-color: var(--purple3);
}
.test-options .ant-checkbox-input:focus+.ant-checkbox-inner,
.test-options .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.test-options .ant-checkbox:hover .ant-checkbox-inner {
	border-color: #d9d9d9;
}
/* incorrect answers */
/* .test-options .incorrectAnswer>.ant-radio-checked .ant-radio-inner,
.test-options .incorrectAnswer>.ant-radio-wrapper:hover .ant-radio,
.test-options .incorrectAnswer>.ant-radio:hover .ant-radio-inner,
.test-options .incorrectAnswer>.ant-radio-input:focus+.ant-radio-inner {
	border-color: red !important;
	box-shadow: none !important;
}
.test-options .incorrectAnswer .ant-radio-inner::after {
	background: red !important;
} */
/* topic tutorial */
.start-btn {
	background-color: var(--purple5) !important;
	color: #000000 !important;
	width: 180px;
	border: none !important;
}
.tutorial-ans label.ant-radio-wrapper.ant-radio-wrapper-checked.options.incorrect-ans{
color: red;
}
.tutorial-ans .test-options .ant-radio-wrapper-checked.options.incorrect-ans {
	border-color: red !important;
}
.tutorial-ans label.ant-radio-wrapper.ant-radio-wrapper-checked.options.correct-ans{
	color:  var(--green1);
}
.tutorial-ans .test-options .ant-radio-wrapper-checked.review.correct-ans {
	border-color: var(--green1) !important;
}
.table-link-btn {
	padding: 0;
	height: auto;
}
.topic-table.customTable .ant-table {
	font-size: 14px;
}
/* custom-tutorial */
.custom-tutorial .ant-checkbox-checked .ant-checkbox-inner{
	background-color: #000080 !important;
	border-color: #000080 !important;
}
.custom-tutorial .ant-checkbox-input:focus+.ant-checkbox-inner,
.custom-tutorial .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-tutorial .ant-checkbox:hover .ant-checkbox-inner{
	border-color: #000080 !important;
}
.custom-tutorial .ant-table-tbody>tr.ant-table-row-selected>td {
	background: #00008012 !important;
}
.custom-tutorial .ant-checkbox-indeterminate .ant-checkbox-inner:after {
	background-color: #000080 !important;
}
.tutorial-info {
	font-size: clamp(1rem, calc(0.9rem + 0.4vw), 1.5rem);
	max-width: -webkit-max-content;
	max-width: max-content;
	margin-right: auto;
	margin-left: auto;
}
.tutorial-info .ant-tag {
	font-size: 1em;
	padding: 4px 7px 6px;
}
.tutorial-info p {
	margin-bottom: 0;
}
.custom-radio-group,
.custom-radio-group span.ant-radio+* {
	font-size: clamp(1rem, calc(0.7rem + 0.4vw), 1.5rem);
}
.custom-table-container [class*="col-"]:first-child{
	padding-left: 0 !important;
}
.custom-table-container [class*="col-"]:last-child{
	padding-right: 0 !important;
}
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-title {
	background-color: #E8E8E8;
	font-weight: 700;
	border-bottom-width: 1px;
	padding: 8px 16px;
	 /* min-height: 60px; */
	display: flex;
	align-items: center;
}
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-thead th.ant-table-column-sort,
.grey-custom-table .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: white;
	color: black;
}
.grey-custom-table .ant-table {
	font-size: 16px;
}
.grey-custom-table .ant-table-filter-trigger {
	background-color: #F0F0F0;
	margin-top: -10px !important;
	margin-bottom: -10px !important;
	margin-right: -12px !important;
	font-size: 16px !important;
	color: #8D8D8D !important;
}
.grey-custom-table.custom-tutorial th:first-child, .grey-custom-table.custom-tutorial td:first-child{
	border-right: 0 !important;
}
.grey-custom-table.custom-tutorial .ant-table-thead>tr>th {
	padding: 16px !important;
}
.custom-tutorial td {
	word-break: break-word !important;
}
.title-checkbox {
	padding-right: 24px !important;
}
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-title,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-tbody>tr>td {
	border-color: #c3c3c3 !important;
	color: #000000;
}
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-tbody>tr>td,
.grey-custom-table .ant-table tfoot>tr>th,
.grey-custom-table .ant-table tfoot>tr>td {
	word-break: break-all;
	line-height: 1.2;
}
.grey-custom-table .ant-table-tbody>tr>td,
.grey-custom-table .ant-table tfoot>tr>th,
.grey-custom-table .ant-table tfoot>tr>td {
	/* padding: 10px 6px !important; */
	padding: 10px 12px !important;
}
.grey-custom-table .ant-table-thead>tr>th {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	height: 40px !important;
}
.grey-custom-table .ant-table-tbody>tr>td {
	height: 40px;
}
/* .grey-custom-table .ant-table-container table > thead > tr > th:first-child,
.grey-custom-table .ant-table-container table > tbody > tr > td:first-child{
border-right: none !important;
} */
/* fullLengthTest */
.full-length-test-container {
	background-color: var(--background-color);
}
/* .full-length-test-container [class*= col-]{
	padding-bottom: 0;
} */
/* .sortable-ghost {
	opacity: .6;
  } */
.drawer-btn.ant-btn{
	font-size: 18px;
	line-height: 1;
}
.ant-btn.timerIcon,
.ant-btn.timerIcon:hover,
.ant-btn.timerIcon:focus,
.ant-btn.timerIcon:active {
	background-color: transparent;
	border: none;
	box-shadow: none !important;
	color: var(--purple3);
	--antd-wave-shadow-color: none !important;
	padding: 0;
	height: auto;
}
/* ul {
	list-style-image: url('../../assets/drag.svg') !important;
	} */
	.drag-icon-size{
		font-size: 30px;
	}
	li.test-sortable p {
		margin-bottom: 0;
	}
.test-sortable li {
	border: 1px solid #d3d3d3;
	padding: 10px;
	border-radius: 30px;
	margin-bottom: 10px;
}
ul.test-sortable {
	list-style-position: inside;
	padding-left: 0;
}
.test-sortable li::marker {
	/* content: url('../../assets/drag.svg') !important; */
	content: '';
}
/* li::marker:active{
	width: 10px !important;
	height: 10px !important;
	color: green;
  } */
.test-dnd-icon {
	color: #000;
    padding: 10px 0 10px 10px;
    font-size: xx-large;
}
.dnd-item {
	grid-gap: 12px;
	gap: 12px;
	display: flex;
}
.dnd-item * {
	margin-bottom: 0;
}
.droppable {
	min-width: 200px !important;
	border-bottom: 1px solid grey;
	margin-right: 0px !important;
	margin-left: 0px !important;
	display: flex;
	justify-content: center;
	align-items: flex-end;
    min-height: 50px;
    position: relative;
    bottom: 10px;
}
.drag-option {
	padding: 10px;
	border-radius: 30px;
}
.drag-option p {
	margin-bottom: 0;
}
.draggable-mr:nth-child(odd){
	margin-right: 2%;
	width: 48% !important;
}
/* notes modal */
/* .notes-modal{
	border: 1px solid #FFEDAE !important;
  } */
.notes-modal .ant-modal-header {
	border-bottom: 2px solid #FFEDAE;
	background-color: #FBF2D5;
}
.notes-modal .ant-modal-body,
.notes-modal .ant-modal-footer {
	background-color: #FEF7E4;
}
.notes-modal::-webkit-input-placeholder {
	color: #C0B481;
}
.notes-modal::placeholder {
	color: #C0B481;
}
.notes-modal textarea.ant-input {
	background-color: transparent;
	border: none;
	box-shadow: none;
	color: black;
}

/* result-page */
.result-table-container.ant-row > [class*="col-"]:first-child{
	padding-left: 0 !important;
}
.result-table-container.ant-row > [class*="col-"]:last-child{
	padding-right: 0 !important;
}
.result-avatar{
	flex: 0 0 64px;
}

.resut-table td {
	word-break: break-word !important;
}
.resut-table .ant-table-title{
	padding: 0 !important;
	min-height: 60px;
	display: flex;
	align-items: center;
}
.resut-table .table-title p:first-child{
	min-height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.resut-table td > .ant-btn {
	height: auto;
	padding: 0;
	border: 0;
	line-height: normal;
}
.initials-bkg {
	background-color: var(--lightblue);
	width: 50px;
	height: 60px;
	border-radius: 50%;
	color: white;
}
.gold-btn.share-score{
	width: auto;
	bottom: 4px;
}
.ant-btn.share-score .anticon{
	margin-left: 0;
}
.user-initials {
	font-size: 1.5rem;
}
.user-details p {
	margin-bottom: 4px;
	line-height: normal;
}
.user-name-result {
	color: var(--purple3);
}
.test-name,
.user-name-result {
	font-size: 1.2rem;
}
.test-date {
	font-size: 1rem;
}
.test-time {
	font-size: 0.9rem;
}
.score-bkg{
	background-color: #EBEEFF;
}
.score-section{
	padding: 10px 12px;
	font-size: clamp(1.3rem, calc(1rem + 1vw), 1.8rem);
	border: 1px solid #c3c3c3;
	border-bottom: 0;
}
.pass-score{
	color: #00A611;
}
.fail-score{
	color: #8D8D8D;
}
.user-detail-wrapper {
	border-bottom: 2px solid var(--purple3);
	padding-bottom: 20px;
}
.grey-custom-table.resut-table .ant-table .ant-table-title {
	padding: 12px;
	text-align: center;
}
.table-title p {
	padding: 6px 12px;
	margin-bottom: 0;
	text-align: center;
}
.title-two {
	background-color: white;
	margin-bottom: 0;
}

.title-two > .ant-btn {
	height: auto;
	padding: 0;
	border: 0;
	line-height: normal;
}
.result-bottom-section p {
	margin-bottom: 0;
	color: black;
}
.correct-answer {
	color: #000;
}
.avg-time {
	color: #8D8D8D;
}
.score-icon.anticon {
	font-size: 30px;
	color: white;
	z-index: 1;
}
.result {
	font-size: 0.8rem;
	margin-top: 18px;
	text-align: center;
}
.hexagon {
	position: relative;
	width: 50px; 
	height: 28.87px;
	margin: 14.43px 0;
	background-color: var(--purple4);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
  }
.hexagon:before,
.hexagon:after {
	content: "";
	position: absolute;
	width: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
}
.hexagon:before {
	bottom: 100%;
	border-bottom: 14.43px solid var(--purple4);
}
.hexagon:after {
	top: 100%;
	width: 0;
	border-top: 14.43px solid var(--purple4);
}
/* hexagon small */
.hex-sm{width: 31px;height: 15.5px;margin: 8px 0;}
.hex-sm .score-icon{font-size: 17px;}
.hex-sm:before{border-bottom: 10.43px solid var(--purple4);} 
.hex-sm:after{border-top: 10.43px solid var(--purple4);}
.hex-sm:before, .hex-sm:after{border-left: 15px solid transparent; border-right: 15px solid transparent;}
.hex-sm .anticon{
	font-size: 20px;
	z-index: 2;
}
/* hexagon large */
.hex-lg{width: 80px;height: 50px;margin: 8px 0;}
.hex-lg .score-icon{font-size: 40px;}
.hex-lg:before{border-bottom: 20px solid var(--purple4);} 
.hex-lg:after{border-top: 20px solid var(--purple4);}
.hex-lg:before, .hex-lg:after{border-left: 40px solid transparent; border-right: 40px solid transparent;}
/* search result */
.test-question.search-result{
	font-size: 18px;
}
  /* xx */
  /* single cat result page */
.attempts{
	color: var(--purple3);
	font-style: italic;
	font-size: medium;
}
  /* xx */
/* review incorrect */

.ant-radio, .ant-checkbox{
	margin-right: 10px;
}
.review-answers .test-options{
	pointer-events: none;
}
.review-counter {
	counter-reset: alphabeticList;
	pointer-events: none;
}
.pointer-none{
	pointer-events: none;
}
.highlighted
{
		background-color: yellow;
}
.options-counter {
	counter-reset: alphabeticList;
}
.options-inner:before {
	content: counter(alphabeticList,upper-alpha);
	font-weight: 500;
	margin-right: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;

}
.options-inner {
	counter-increment: alphabeticList;
	/* pointer-events: none; */
	display: flex;
  align-items: center;
}
 /* xx */
/* drag and drop counter */
 .radio-grp {
	counter-increment: alphabeticList;
	pointer-events: none;
	display: flex;
  align-items: center;
 }
 .dnd-counter-inner {
	counter-increment: alphabeticList;
	/* pointer-events: none; */
	display: flex;
  align-items: center;
}
 .dnd-counter-inner:before {
	content: counter(alphabeticList,upper-alpha);
	font-weight: 500;
	margin-left: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 10px;
 }
 .dnd-item.dnd-counter-inner {
	 grid-gap: 0;
	 gap: 0;
 }
 /* dnd counter for tests */
 .dnd-counter {
	font-weight: 500;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 20px;
 }
 /* xx */
 /* arrange the seq counter */
 .seq-counter {
	font-weight: 500;
	margin-right: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 10px;
 }
 .list-item p {
	 margin-bottom: 0;
 }
 /* xx */

.review .ant-radio, .review .ant-radio-inner{
	visibility: hidden;
}
.review.correct-ans .ant-radio, .review.incorrect-ans .ant-radio {
	visibility: inherit;
}
.review.correct-ans .ant-radio:before{
	content: '\2714';
	color: var(--green1);
	font-size: 22px;
	position: absolute;
	top: -6px;
	background-color: white;
	z-index: 1;
}
.review.correct-ans, .review.correct-ans:hover, .review.correct-ans:focus {
	color: var(--green1);
	border: 2px solid var(--green1);
}
.review.incorrect-ans .ant-radio:before{
	content: '\00D7';
	color: var(--red1);
	font-size: 34px;
	position: absolute;
	top: -8px;
	background-color: #fff;
	z-index: 1;
	height: 30px;
	line-height: 1;
}
.review.incorrect-ans, .review.incorrect-ans:hover, .review.incorrect-ans:focus {
	border: 2px solid var(--red1);
	color: var(--red1);
}
.review-test{
	font-size: 18px;
}
.test-options .options.review:not(.correct-ans, .incorrect-ans) {
	color: inherit;
	border: 2px solid #d9d9d9;
}
.ans-display {
	font-size: 14px;
}
/* xx */
.choose-test-card-wrapper .ant-card-body:not(.cat-card .ant-card-body){
	background: #ececec !important;
	padding: 24px 12px;
  }
.cat-card .ant-card-body{text-align: center; padding: 24px 10px;}
.cat-card .ant-card-head-title{
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.type-modal .ant-btn{height: 142px;color: #767732;}
.type-modal .ant-btn:focus,.type-modal .ant-btn:active{background: #E4E893; color: #767732; border-color: #8D8D8D;}

.collapse-notes .ant-collapse-header{background-color: #FBF2D4;border: 1px solid #FFECAE;}
.collapse-notes .ant-collapse-content-box{background-color: #FDF7E3; border: 1px solid #F0F0F0; border-top: none;}

.red-color-time{
	color: red !important;
}
.black-color-time{
	color:black;
}
.custom-placeholder .ant-select-selection-placeholder {
  color: black !important;
}
td.ant-table-cell.td-right span {
	text-align: right;
	width: 55px;
	display: block;
	margin: auto;
}
.space-quit .ant-space-item:last-child{
	margin-left: auto;
}
.mytest-star {
	position: absolute;
	top: 3px;
	left: 18px;
}
.th-center th{
	text-align: center !important;
	}
/* compare score */
.hex-sm.level0{background-color: #F7EFFF; color: white;}
.level0.hex-sm:before {border-bottom: 10.43px solid #F7EFFF;} 
.level0.hex-sm:after{border-top: 10.43px solid #F7EFFF;}

.hex-sm.level1{background-color: var(--green2); color: white;}
.level1.hex-sm:before {border-bottom: 10.43px solid var(--green2);} 
.level1.hex-sm:after{border-top: 10.43px solid var(--green2);}
.hex-sm.level2{background-color: var(--badge2); color: white;}
.level2.hex-sm:before {border-bottom: 10.43px solid var(--badge2);} 
.level2.hex-sm:after{border-top: 10.43px solid var(--badge2);}
.hex-sm.level3{background-color: var(--orange-3); color: white;}
.level3.hex-sm:before {border-bottom: 10.43px solid var(--orange-3);} 
.level3.hex-sm:after{border-top: 10.43px solid var(--orange-3);}
.hex-sm.level4{background-color: var(--purple3); color: white;}
.level4.hex-sm:before {border-bottom: 10.43px solid var(--purple3);} 
.level4.hex-sm:after{border-top: 10.43px solid var(--purple3);}
.hex-sm.level5{background-color: var(--purple2); color: white;}
.level5.hex-sm:before {border-bottom: 10.43px solid var(--purple2);} 
.level5.hex-sm:after{border-top: 10.43px solid var(--purple2);}

.selfcompare-chart svg g g g g rect {
	stroke-width: 1px;
  stroke: #6d6d6d;
	/* display: block; */
}

/* svg g g rect {
	display: none;
} */
.nograph-div{
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 24px;
	color: black;
}
.nograph-div a {
	color: var(--purple3);
}
/* error message on compare score */
div[id*="google-visualization-errors-all-"] *{
	display: none;
}
/* xx */
.ant-btn-link.search-link {
	height: auto;
	display: flex;
	text-align: left;
	white-space: break-spaces;
	grid-gap: 10px;
	gap: 10px;
}
.ant-btn-link.search-link p {
	margin-bottom: 0;
}
/* my test */
.my-test .ant-card-small>.ant-card-head{
	padding: 0px 26px;
}
.my-test .ant-table-pagination.ant-pagination {
	padding: 0px 15px;
}
.mytest-table .ant-table-tbody>tr>td {
	vertical-align: top;
} 
.zoom-btn, .zoom-btn:hover, .zoom-btn:focus {
	background: transparent;
	border: navajowhite;
	font-size: clamp(1.2rem,calc(1.2rem + 1vw),1.7rem);
	padding: 0;
	color: #333333;
}
.zoom-btn.iconDisable, .zoom-btn.iconDisable:hover, .zoom-btn.iconDisable:focus {
	color: lightgray;
	cursor: not-allowed;
}
.gap-table td.result-col{
	padding-right: 25px !important;
}
/* xx */
.test-question table, .test-question th, .test-question td{border: 2px solid #000 !important;}
.purple-border{border: 1px solid var(--purple2);}
.purple-border .zoom-btn, 
.purple-border .zoom-btn:focus, 
.purple-border .zoom-btn:hover{
	font-size: 19px !important;
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}
.purple-border .ant-badge-status{
	line-height: normal !important;
}
.drag-question .ant-space{
	grid-gap: 0 !important;
	gap: 0 !important;
}
@media screen and (max-width: 991px) {
	.test-options.ant-radio-group .ant-radio-wrapper {
		padding: 10px 18px !important;
	}
}
@media screen and (max-width: 767px) {
	.categoryDetails {
		margin-bottom: 20px;
	}
	.user-details {
		flex: auto;
	}
	.result-btns {
		margin-top: 8px;
	}
}

.button-container button:not(:last-child) {
	margin-right: 10px
}
.slp-card .ant-card{
	border-radius: 8px;
	overflow: hidden;
}
.slp-card .ant-card-body{padding: 0; background: #FAFAFA; height: 100%;}
.crd-arrow{
	position: absolute;
	right: 0;
	bottom: 0;
}
.peach-crd{background: var(--orange-3) !important;}
.smart-crd-section{
	display: flex;
	justify-content: center;
	align-items: center;
}
.smart-crd-section .ant-carousel{width: 90%;}
.smart-card{background: var(--purple5) !important;border-radius: 8px !important;}
.smart-card .ant-card-body{min-height: 203px;}
.sch-card{
	/* width: 190px; */
	/* min-height: 205px; */
	border-radius: 8px !important;
}
.glance-card {
	font-size: 14px;
}
.glance-card .ant-card-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.glance-card .dash-title {
	padding-bottom: 20px;
	display: block;
}
.glance-data span {
	text-align: left;
	display: block;
	margin: auto;
}
.dash-title {
	font-size: small;
	text-align: left;
	text-transform: uppercase;
	font-weight: 500;
}
.sch-card .ant-card-head{padding: 0 15px;}
.sch-card .ant-card-head-title{padding: 10px 0;}
.sch-card .ant-card-body{
	padding-top: 0;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
}
.sch-card .ant-form-item{margin-bottom: 4px;}
.blue-btn{
	text-transform: uppercase;
	color: var(--purple3) !important;
	border-color: var(--purple5) !important;
	background: var(--purple5) !important;
	font-weight: 500 !important;
	font-size: small !important;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px !important;
  }

.streak{
	display: flex;
	align-items: center;
	height: 40px;
	border-radius: 8px;
	background: linear-gradient(90deg, #FFA800 -27.48%, var(--orange-3) 50%);
}
.lit{
	font-size: 5rem;
	/* background: linear-gradient(180deg, rgba(255, 0, 0, 0.85) 0%, #FFA800 100%); */
	color: #FF550A !important;
	position: relative;
	top: -17px;
	left: 7px;
}
.day-count{
	font-size: 25px;
	font-weight: 500;
	line-height: 13px;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.streak-days{
	position: absolute;
	left: 28px;
	top: 0px;
}
.int-3{left: 20px !important;}
.streak-days small{font-size: 9px;}
.badges-pts{
	display: flex;
	justify-content: space-between;
}
.font-22{font-size: 22px;}
.font-18{font-size: 18px;}
.font-16{font-size: 16px;}
.font-14{font-size: 14px;}
.btn-bottom-right{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
}
.gray-btn{
	border-color: #BFBFBF !important;
	color: #58554A !important;
}
.streak-middle{
	display: flex !important;
	align-items: center;
}
.progress-chart-crd{height: 100%;}
.progress-chart{
	width: 100%;
	height: 260px;
}
.progress-chart svg > g:last-child:not(:first-child) {
	text-transform: capitalize;
}
.progress-chart .google-visualization-tooltip {
	box-shadow: 2px 2px 4px lightgray;
	min-width: 170px;
}
.progress-chart div.google-visualization-tooltip > ul > li > span {
	line-height: normal;
	padding: 0;
	margin: 0;
}
 /* g *  {
	width: 20px;
	background-color: #FF550A !important;
} */
.feedback-form .ant-form-item-label{font-weight:700;}
.feedback-form {
	counter-reset: section;
}
.feedback-form .ant-form-item-label:not(:nth-child(2)):before{
	counter-increment: section;
	content:counter(section) ") ";
	font-weight: bold;
}
/* Coupon */
.coupon {
	border: 3px dotted #DFE0EB;
	width: 100%;
	border-radius: 4px;
}
.promo-container {background-color: #f1f1f1;}
.promo {
  background: #ccc;
  padding: 3px;
}
.smart-card-bg-img::before {
	content: "";
	background-image: url(../../static/media/smt-crd-bg.06dbe818.svg) !important;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.2;
}
.smart-card-bg-img{overflow: hidden;}
.smart-card-bg-img .ant-card-body{position: relative;}
/* progress on test */
.progress-chart svg g g g g rect {
	stroke-width: 0px;
  stroke: transparent;
	box-shadow: none;
}
.sm-text-btn{
	border-radius: 8px!important;
	font-weight: 500!important;
    font-size: small!important;
	display: flex;
    align-items: center;
}
.shadow-none{
	box-shadow:none;
}
.black-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #0E0E0F;
}
.dark-orange-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #CC5500;
}
.light-orange-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #FFC000;
} 
.blue-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #0045AA;
} 
.indigo-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #513BC0;
} 
.olive-tick.ant-progress-circle.ant-progress-status-success .ant-progress-text{
	color: #808000;
} 
@media screen and (min-width: 992px) {
.smart-crd-section .ant-carousel{width: 80%;}
.streak-days{left: 42px;top: 40px;}
.int-3{left: 33px !important;}
}

.note-card{border: 1px solid #FFEDAE !important;}
.note-card .ant-card-head{
    border-bottom: 2px solid #FFEDAE;
    background-color: #FBF2D5;
}
.note-card .ant-card-body{background-color: #FEF7E4;}
.note-card .ant-typography{
    font-weight: 500;
    color: black;
}
.note-card .ant-card-body {padding: 0 !important;}
.note-card-body{padding: 12px; min-height: 112px;}
.note-card-footer{
    border-top: 1px solid #FFEDAE;
    padding: 5px 12px;
}

/* INSTRUCTIONS */
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    float: right;
    margin-top: 6px;
}
.instructions .ant-btn.btnDb ,.instructions .ant-btn.btn-submit, .instructions .qust-title{
    min-width: 85px;
    height: 24px;
    font-size: 12px;
    padding: 4px;
}
.inst-time-block {
    display: inline-block;
}
.inst-time-block button, .inst-time-block button:hover, .inst-time-block button:focus {
    height: auto!important;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    color: #767732;
    padding: 12px;
    width: 130px;
    border: 1px solid #d9d9d9;
}
.inst-label {
    min-width: 130px;
    display: inline-block;
}
.instructions .ant-card-small>.ant-card-head {
    background-color: var(--purplehover);
}
.hourglass {
    color: #767732;
}
.instructions .ant-collapse-content>.ant-collapse-content-box p:only-child {
    margin-bottom: 0;
}
.instructions .icon-md {
    font-size: 18px;
}
.inst-btn-wrapper {
    position: absolute;
    right: 4px;
    top: 0;
}
.inst-side-header{
    position: absolute;
    top: 0;
}
.instructions .ant-card-head-title {
    overflow: inherit;
    white-space: normal;
}
/* feedback analysis */
.comment-popover.ant-popover{
    max-width: 500px;
}
.comment-popover .ant-popover-content {
    white-space: break-spaces;
}

.div-below-grid{
    margin-top: -35px;
}

@media screen and (max-width: 767px) {
    .inst-btn-wrapper {
        position: static;
        float: right;
    }
}
.flashcard-progress .ant-progress-inner{border-radius: 0;}
.flash-tabs .ant-tabs-extra-content{width: 77%;}
.flash-tabs .ant-row .flash-filter{display: grid;place-items: end;}
.flash-tabs .ant-tabs-nav-wrap{justify-content: flex-end;}
.flash-tabs .ant-tabs-nav, .flash-tabs .ant-tabs-nav::before{border-bottom: none !important; margin-bottom: 0 !important;}
.flash-tabs .ant-tabs-ink-bar{display: none;}

.flash-tabs {padding-top: 1.2rem;}
.flashcard-progress {
  margin-top: 1.2rem;
  margin-bottom: 1.6rem;
}
.gap-table td.sr-no-col {
  text-align: right;
  margin: auto;
  padding: 0 20px !important;
  width: 60px;
}
/* Flash Card */
.flashcard-title {
  display: block;
  padding-top: 1.2rem;
}

.flashcards .ant-card{
  height: 365px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: thin;
}
/* #content{
  height: 365px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: thin;
} */

.flashcards .ant-card::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.flashcards .ant-card::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: #306DA5;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.flashcards .ant-card-head-title{
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-shadow: -1px 2.5px #00000040;
}
.flashcards .ant-card-head{border-bottom: none;}
.flashcards .ant-card-body{font-size: x-large; padding: 12px 40px !important;}

.flashcardsQnA .ant-card{height: 182.5px;cursor: default;}
.flashcardsQnA .ant-card-body{font-size: large;}
/* .flashcardsQnA .ant-card:nth-child(2){background: #F4F9FF;} */

/* center items in the flashcard */
.flashcard-items-center{display: grid !important;align-items: center;}
.flashcard-items-center .ant-card-body{text-align: center;}
.flashcard-items-center .ant-card-head{position: absolute; top: 0; width: 100%;}
.flashcard-items-center ul {
  text-align: left;
  width: 80%;
  margin: auto;
}
/* Flash list */
/* .flash-list .ant-card-head-title::before{
    content: 'Q';
    font-size: 24px;
    font-weight: 400;
    text-shadow: -1px 2.5px #00000040;
    margin-right: 5px;
} */
/* .flash-list .ant-card-head-title p:nth-child(2) {
  margin-left: 23px;
} */
.flash-list .flash-Q{
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
}
.flash-A {
  content: 'A';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
  line-height: normal;
}
/* .flash-list.flash-listQA .ant-card-head-title p:first-child:before{
  content: 'Q';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
} */
.flash-listQA .ant-card-small>.ant-card-head {
  background-color: var(--purplehover);
  border: 1px solid var(--purple3);
}
.flashcardsQnA .flashQnA-Q.ant-card {
  background-color: var(--purplehover);
}
.flash-listQA p {
  margin-bottom: 0;
}
/* .flash-answer:before {
  content: 'A';
  font-size: 24px;
  font-weight: 400;
  text-shadow: -1px 2.5px #00000040;
  margin-right: 5px;
  line-height: normal;
} */
.flash-qa{
  display: flex;
  align-items: baseline;
 }
 .flash-list.flash-listQA .ant-card-body {
  margin-left: 0px;
}
.flash-list .ant-card-head-title{white-space: break-spaces;}

.flash-tabs .ant-tabs-tab .anticon{margin-right: 0;}

.flash-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
.flip-bookmrk-container{
  text-align: right;
  width: 89%;
}
.flip-bookmrk-container .flip-bookmark{
  position: absolute;
  top: 7px;
  z-index: 10000;
}
.mytest-star {
	position: absolute;
	top: 4px;
	left: 18px;
  font-size: 14px;
}
.mytest-star path{
  fill: #FFD814 !important;
}
.flashcardlist-card .ant-card-head{
  padding: 0px 24px;
}
.reviewflashcard{
  position: absolute;
  left: -10px;
  top: 50%;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
	color: rgb(163, 163, 163);
  font-style: italic;
	font-size: medium;
	border: none;
}
.reviewcard{
	color: rgb(163, 163, 163);
  font-style: italic;
	font-size: medium;
	border: none;
}
.reviewedListFlashcard{
  font-style: italic;
  color: rgb(163, 163, 163);
  font-size: small;
}
@media (min-width: 768px) {
 .flash-container {width: 750px;}
 .flip-bookmrk-container {width: 94%;}
}
@media screen and (min-width: 992px) {
 .flash-list .ant-card-body{margin-left: 22px;}
 .flash-container {width: 970px;}
}
@media (min-width: 1200px) {
 .flash-container {width: 768px;}
}
.div{
    background-color: #e6e7e9;
}
/* gap table */
.gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
    padding: 1rem;
}
.gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid var(--purple3);
}
.gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid var(--purple3);
}

.gap-table th,
.gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
	padding: 5px;
  	text-align: left;
}

.gap-table th:first-child,
.gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.gap-table th:last-child,
.gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}
.div2{
	padding: 1.5rem !important;
}
.reg-table td {
	min-width: 38px; 
	text-align: center;
}
.reg-table th {
	text-align: left;
}
.table .ant-table-tbody>tr>td:not(:first-child){
	text-align: right;
}
.table{
	padding: 1rem !important;
}
/* .table2{
	padding: 1rem !important;
} */
.ant-table-summary>tr>td.last-element:last-child span{
	text-align: right;
	width: 24px ;
	display: block;
	margin: auto;
}
.table .ant-table-tbody>tr>td, 
.table .ant-table-thead>tr>th, 
.table .ant-table tfoot>tr>td, 
.table .ant-table tfoot>tr>th {
    position: relative;
    padding: 12px;
    overflow-wrap: break-word;
}
.table2 .ant-table-tbody>tr>td, 
.table2 .ant-table-thead>tr>th, 
.table2 .ant-table tfoot>tr>td, 
.table2 .ant-table tfoot>tr>th {
    position: relative;
    padding: 5px;
    overflow-wrap: break-word;
}
th:last-child.td-registartion{
	text-align: center;
}
td:last-child.td-registartion{
    text-align: right;
    display: block;
    padding-right: 70px !important;
}
td:last-child.td-registartion-role span{
    text-align: right;
    width: 25px;
    display: block;
    margin: auto;
}
td:last-child.td-total-registration span{
    text-align: right;
    width: 65px;
    display: block;
    margin: auto;
}
.label-width label{
	width: 100px !important;
}
.gap{
	grid-gap: 6px !important;
	gap: 6px !important;
}
.gap-40{
	grid-gap: 40px !important;
	gap: 40px !important;
}
.border{
	border: 1px solid #e6e7e9;
}
.gap-status{
	grid-gap: 22px !important;
	gap: 22px !important;
}
/* .ant-btn:focus, .ant-btn:hover .button {
    border-color: #e6e7e9
} */
.card.ant-card-small>.ant-card-head {
    min-height: 36px;
    font-size: 14px;
	padding: 0 24px;
}
.a-color .a:hover{
	color: #ffffff;
}
.react-qill-editor .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 0;
    height: 200px !important;
}
.admin-flashcard-items-center .ant-card-body{
	text-align: left !important;
	width: 50% !important;
	margin: auto !important;
	height: 300px !important;
}
.admin-flashcard-items-center .ant-card-head{position: absolute; top: 0; width: 100%;}
.green-btn {
	border-color: #b7e9b9!important;
	background: #b7eb8f !important ;
	color: black !important;
	/* &:hover {
		background-color: var(--primary-hover-color) !important;
		border-color: var(--primary-hover-color) !important;
	} */
}
/* .green-btn .ant-btn:hover {
    text-decoration: none;
    background: #b7eb8f;
	color: black;
} */
.edit-butoon{
	background-color: var(--purple3)!important;
	color: white!important;
	border-color: var(--purple3)!important;
}
.filterbutton{
	background-color:#e6e7e9!important;
	color: black!important;
	border-color: #e6e7e9!important;
}
/* .ant-btn:active, .ant-btn:focus, .ant-btn:hover .edit-butoon {
	background-color: var(--purple3)!important;
	color: white !important;
} */
/* .ant-btn:active, .ant-btn:focus, .ant-btn:hover .green-btn {
	background: #b7eb8f;
	color: black;
} */
.ant-card-flashcard .ant-card {
    width: 60%;
    margin: auto;
	height:520px !important;
}
.ant-card-flashcard .ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
    padding: 8px 0;
    font-size: 35px;
}
.card-height .ant-card {
    height: 450px !important;
}
.div-bottom{
	position: absolute;
    bottom: 0;
    padding: inherit;
    margin: auto;
    width: 90%;
}
.edit .ant-radio  {
    margin-right: 2px;
}
.modal-close-button .ant-modal-close-x {
    width: 30px;
    height: 24px;
    line-height: 30px;
    color: red;
}
.radio-button-lable .ant-checkbox-wrapper, .ant-progress-text, .ant-radio-wrapper {
    color:rgba(0,0,0,.85);
}
.bold-label label:not(.ant-radio-wrapper){
	font-weight: 500;
}
.label-width label{
	min-width: 162px;
	padding: 10px;
}
.card-padding .ant-card-small>.ant-card-body {
    padding: 1px;
}
.input-width .ant-input{
	width: 90.6%;
}
.ml-175px{
	margin-left: 175px;
}
.popover-ml-3 .ant-popover-content{
	margin-left:1rem !important;
}
.disable_faq_ans .ql-editor{
	background-color: #dddddd;
	cursor: no-drop;
}

/* .admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-thead th.ant-table-column-sort,
.admin-table .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: #E8E8E8;
	color: white;
} */
.admin-table .ant-table.ant-table-bordered > .ant-table-title,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tbody
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> th,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.admin-table
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> td,
.admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-tbody > tr > td {
	border-color: #b1afaf !important;
}
/* .admin-table .ant-table-thead > tr > th:first-child,
.admin-table .ant-table-tbody > tr > td:first-child {
	border-left: 1px solid#8D8D8D !important;
} */
.admin-table .ant-table {
	font-size: 16px;
	border: 1px solid #b1afaf;
}
.admin-table .ant-table-tbody > tr:nth-child(even) {
	background: #e8e8e8;
}
.admin-table td.ant-table-column-sort {
	background: inherit;
}
.admin-table .ant-table-thead > tr > th,
.admin-table .ant-table-tbody > tr > td,
.admin-table .ant-table tfoot > tr > th,
.admin-table .ant-table tfoot > tr > td {
	padding: 6px 12px!important;
	font-size: 13px;
	color:black;
}
.admin-table .ant-table-tbody > tr > td:not(:last-child),
.admin-table .ant-table-thead > tr > th:not(:last-child){
	border-right: 1px solid #b1afaf !important;
}
.admin-table .ant-table-thead > tr > th{
	height: 50px;
}
.admin-table-width .ant-table-thead > tr > th{
	width: 220px !important;
}
.admin-table td{
	height: 40px;
}
.admin-table.ant-table-tbody>tr:last-child>td {
	border-bottom:none
}
.admin-table .ant-table-column-sorters {
	justify-content: start;
}
.admin-table .ant-table-column-sorter {
	margin-left: 10px;
}
.admin-table .ant-table-column-title {
	flex: initial;
}
td.ant-table-cell.td-right span{
	text-align: right;
	width: 55px;
	display: block;
	margin: auto;
}
td.ant-table-cell.center-star span{text-align: center !important;}
td.ant-table-cell.center-star .mytest-star{left: 25px !important;}
td.ant-table-cell.td-right-attemtedon span{
	text-align: right;
	width: 120px;
	display: block;
	margin: auto;
}

/* ----------  ------------ */
/* gap table */
.admin-gap-table table {
	border-collapse: initial !important;
	border-spacing: 0 12px !important;
	margin-top: -10px;
	margin-bottom: -10px;
}

.admin-gap-table tr.ant-table-row:hover > td:first-child {
	border-left: 1px solid var(--purple3);
}
.admin-gap-table tr.ant-table-row:hover > td:last-child {
	border-right: 1px solid var(--purple3);
}
.admin-gap-table tr.ant-table-row:hover > td {
	border-top: 1px solid var(--purple3);
	border-bottom: 1px solid var(--purple3);
}

.admin-gap-table th,
.admin-gap-table td {
	border-top: 1px solid #e6e7e9;
	border-bottom: 1px solid #e6e7e9;
	padding: 7px 10px !important;
}

.admin-gap-table th:first-child,
.admin-gap-table td:first-child {
	border-left: 1px solid #e6e7e9;
	border-radius: 8px 0 0 8px !important;
}

.admin-gap-table th:last-child,
.admin-gap-table td:last-child {
	border-right: 1px solid #e6e7e9;
	border-radius: 0 8px 8px 0 !important;
}
.admin-gap-table td:not(:last-child) {
	border-right: 1px solid #e6e7e9;
}


/* ----------student activity modal------------ */
.stud-details{
	display: grid;
	grid-template-columns: 40% 60%;
	grid-column-gap: 12px;
}

/* ----------code generation------------ */
.button-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}
.button-wrapper button {
	min-width: 100px;
}
/* ----------feedback analysis------------ */
.red {
	color: red;
}
.green {
	color: green;
}

.admin-dashboard {
  background-color: #F2F4F5;
}
.reg-tab-outer {
  --period: #56AAF2;
  --country: #F5A752;
  --state: #77CEBB;
  --university: #766EDB;
  --role: #197BBD;
  color: white;
}
.reg-tab-row {
  margin: auto !important;
  margin-right: -20px !important;
}

.reg-tab {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  width: calc( 20% - 20px );
  min-height: 100px;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reg-tab-period {
  background-color: var(--period);
}
.reg-tab-country {
  background-color: var(--country);
}
.reg-tab-state {
  background-color: var(--state);
}
.reg-tab-university {
  background-color: var(--university);
}
.reg-tab-role {
  background-color: var(--role);
}
.slp-admin-card .ant-card{
	border-radius: 8px;
	overflow: hidden;
  height: 100%;
}
.slp-admin-card .ant-card-body{
  padding: 0;
  background: #FAFAFA;
  height: 100%;
}
.crd-arrow{
	position: absolute;
	right: 0;
	bottom: 0;
}
.progress-gap {
  grid-gap: 20px;
  gap: 20px;
}
.color-crd-loop .slp-menu-card:nth-child(4n+1) .anticon{
  color: var(--purple7);
}

.color-crd-loop .slp-menu-card:nth-child(4n+2) .anticon{
  color: var(--olive);
}

.color-crd-loop .slp-menu-card:nth-child(4n+3) .anticon{
  color: var(--orange-4);
}

.color-crd-loop .slp-menu-card:nth-child(4n+4) .anticon{
  color: var(--lightblue);
}
.card-purple {
  --cardlightpurple: #EDDDFC;
  --carddarkpurple: #8A4D92;
  background: var(--cardlightpurple);
}
.card-purple .anticon {
  color: var(--carddarkpurple);
}
.card-yellow{
  --cardlightyellow: #E7E89F;
  --carddarkyellow: #767732;
  background: var(--cardlightyellow);
}
.card-yellow .anticon {
  color: var(--carddarkyellow);
}
.card-orange{
  --cardlightorange: #FCEBDB;
  --carddarkorange: #F39C12;
  background: var(--cardlightorange);
}
.card-orange .q-icon {
  color: var(--carddarkorange);
}
.card-blue{
  --cardlightblue: #BFD9F1;
  --carddarkblue: #197BBD;
  background: var(--cardlightblue);
}
.card-blue .anticon {
  color: var(--carddarkblue);
}
.prog-per {
  color: rgb(164, 164, 164);
}
.hig-reg-table .ant-table-thead {
  display: none;
}
.faq-accordion.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--purplehover);
  box-shadow: rgb(19 30 41 / 7%) 0px 7px 12px 0px;
  color: #222;
  line-height: 32px;
}
.faq-card .ant-card-body{
  background-color: var(--purplehover);
  box-shadow: 0 7px 12px 0 rgb(19 30 41 / 7%);
  color: #222;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  padding: 12px 16px;
}
.faq-arrow.anticon {
  margin-right: 12px;
  font-size: 12px;
}
.arrow-rotate {
  -webkit-transform: rotate(90deg) .24s;
          transform: rotate(90deg) .24s;
}
@media (max-width: 992px) {
	.faq-accordion img{
    width: 100%;
    height: auto;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

