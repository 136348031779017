/* category list */
.category-list-container {
	font-size: clamp(0.8rem, calc(1vw + 0.9rem), 1.4rem);
}
.categories-list {
	background-color: var(--purple5);
	color: #000000;
	text-align: center;
	padding: 10px 26px;
	min-height: 80px;
}
.categories-list .anticon {
	float: left;
	/* position: absolute;
	left: 30px; */
}
.categories-list svg {
	height: clamp(2rem, 1rem + 1.4vw, 3rem);
	width: auto;
}
.categories-list h1{
	color: white;
	font-weight: normal !important;
}
.ant-btn.categories-list {
	background-color: var(--purple5);
	border: none;
	color: #000000;
	text-align: center;
	padding: 10px 30px;
	min-height: 70px;
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.5rem);
}
.ant-btn.categories-list:hover,
.ant-btn.categories-list:focus {
	background-color: var(--purple5);
	color: #000000;
	box-shadow: 0px 0px 12px grey;
}
.categoryDetails button {
	font-size: inherit;
}
.categoryDetails button, .categoryDetails button:hover, .categoryDetails button:focus {
	background-color: inherit;
}
/* select Tutorials */
.ant-row.select-tut-outer {
	max-width: 1400px;
	margin-top: 60px !important;
	padding-bottom: 40px;
}
.border-bottom {
	border-bottom: 1px solid #000000;
}
/* categoryTutorial */
.category-tutorial-container {
	background-color: var(--background-color);
}
.test-drawer .ant-drawer-body{
	padding: 0 !important;
}
.test-drawer .grey-custom-table .ant-table {
	font-size: 1rem;
}
/* .test-drawer .grey-custom-table .ant-table tr th {
	display: none;
} */
.test-drawer .test-icon-container .anticon,
.test-drawer .test-question-details .anticon,
.test-icon-container .anticon,
.test-question-details .anticon {
	font-size: clamp(1.2rem, calc(1.2rem + 1vw), 1.7rem);
}
.test-drawer .test-icon-container img,
.test-drawer .test-question-details img,
.test-icon-container img,
.test-question-details img  {
	/* width: clamp(0.6rem, calc(1vw + 0.6rem), 1.5rem); */
	height: clamp(1.2rem, calc(1.2rem + 1vw), 1.7rem);
	width: auto;
}
.test-drawer .grey-custom-table .ant-table td .ant-btn-link {
	padding: 0;
}
.test-question-details {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.5rem);
	line-height: normal;
}
.options {
	padding: 14px 30px !important;
	border: 2px solid #D9D9D9;
	border-radius: 50px;
	width: 100%;
	align-items: center;
}
.options .ant-radio, .options .ant-checkbox {
	margin-top: -6px;
}
.options span:not(.ant-radio, .ant-checkbox){
	/* font-size: clamp(1rem, calc(1rem + 0.8vw), 1.2rem); */
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.4rem);
	font-family: inherit !important;
}
label.ant-radio-wrapper.ant-radio-wrapper-checked.options {
	color: var(--purple3);
}
.options p {
	margin-bottom: 0;
	line-height: normal;
}
.test-question p, .test-question {
	margin-bottom: 0;
}

.test-btn-container .ant-btn.btnDb:disabled, 
.test-btn-container .ant-btn.btnDb:disabled:active, 
.test-btn-container .ant-btn.btnDb:disabled:focus, 
.test-btn-container .ant-btn.btnDb:disabled:hover{
	pointer-events: none!important;
	background-color: var(--purple2);
	color: white;
}
.flex-direction-col{
	flex-direction: column;
}
.test-question,
.test-case-study {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.4rem);
	line-height: 1.4;
}
.test-options .options:hover,
.test-options .options:focus {
	border: 2px solid var(--purple3);
	color: var(--purple3);
}
.test-question > div {
	font-family: inherit !important;
}
.test-question table {
	margin-top: 20px;
	width: 80% !important;
	font-family: inherit;
	margin: auto;
}
.test-question table td p {
	margin-bottom: 8px;
}
.qust-title {
	background: var(--purple3);
	color: white;
	padding: 0 6px 3px;
	font-size: 20px;
	border: none;
}
.qust-title:hover,.qust-title:focus, .qust-title:active {
	background: var(--purple3)  !important;
	color: white !important;
	padding: 0 6px 3px;
	font-size: 20px;
}
.cat-title {
	color: var(--purple3)
}
.highlight.anticon {
	color: #00A96C;
}
.strike.anticon {
	color: #333333;
}
.flag.anticon {
	color: #750D37;
}
.flag.anticon svg{
	fill: #750D37;
}
.flag.anticon svg path{
	stroke: #750D37;
}
.explanation .ant-card-head,
.explanation .ant-card-body {
	font-size: clamp(1rem, calc(1rem + 0.7vw), 1.3rem);
	line-height: 1.4;
}
.explanation .ant-card-body p {
	margin-bottom: 0;
}
.next.anticon,
.prev.anticon,
.close.anticon {
	font-size: 22px;
}
.next.anticon,
.prev.anticon {
	color: #767732;
}
.close.anticon {
	color: #EB5757
}
.test-footer {
	width: max-content;
	margin-left: auto;
}
.test-options .ant-radio-checked .ant-radio-inner,
.test-options .ant-radio-wrapper:hover .ant-radio,
.test-options .ant-radio:hover .ant-radio-inner,
.test-options .ant-radio-input:focus+.ant-radio-inner {
	border-color: var(--purple3) !important;
	box-shadow: none !important;
}
.test-options .ant-radio-wrapper-checked {
	border-color: var(--purple3) !important;
	box-shadow: none !important;
}
.test-options .ant-radio-inner::after {
	background: var(--purple3) !important;
}
.test-options .ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--purple3);
	border-color: var(--purple3);
}
.test-options .ant-checkbox-checked:after {
	border-color: var(--purple3);
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.options {
	color: var(--purple3);
	border-color: var(--purple3);
}
.test-options .ant-checkbox-input:focus+.ant-checkbox-inner,
.test-options .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.test-options .ant-checkbox:hover .ant-checkbox-inner {
	border-color: #d9d9d9;
}
/* incorrect answers */
/* .test-options .incorrectAnswer>.ant-radio-checked .ant-radio-inner,
.test-options .incorrectAnswer>.ant-radio-wrapper:hover .ant-radio,
.test-options .incorrectAnswer>.ant-radio:hover .ant-radio-inner,
.test-options .incorrectAnswer>.ant-radio-input:focus+.ant-radio-inner {
	border-color: red !important;
	box-shadow: none !important;
}
.test-options .incorrectAnswer .ant-radio-inner::after {
	background: red !important;
} */
/* topic tutorial */
.start-btn {
	background-color: var(--purple5) !important;
	color: #000000 !important;
	width: 180px;
	border: none !important;
}
.tutorial-ans label.ant-radio-wrapper.ant-radio-wrapper-checked.options.incorrect-ans{
color: red;
}
.tutorial-ans .test-options .ant-radio-wrapper-checked.options.incorrect-ans {
	border-color: red !important;
}
.tutorial-ans label.ant-radio-wrapper.ant-radio-wrapper-checked.options.correct-ans{
	color:  var(--green1);
}
.tutorial-ans .test-options .ant-radio-wrapper-checked.review.correct-ans {
	border-color: var(--green1) !important;
}
.table-link-btn {
	padding: 0;
	height: auto;
}
.topic-table.customTable .ant-table {
	font-size: 14px;
}
/* custom-tutorial */
.custom-tutorial .ant-checkbox-checked .ant-checkbox-inner{
	background-color: #000080 !important;
	border-color: #000080 !important;
}
.custom-tutorial .ant-checkbox-input:focus+.ant-checkbox-inner,
.custom-tutorial .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-tutorial .ant-checkbox:hover .ant-checkbox-inner{
	border-color: #000080 !important;
}
.custom-tutorial .ant-table-tbody>tr.ant-table-row-selected>td {
	background: #00008012 !important;
}
.custom-tutorial .ant-checkbox-indeterminate .ant-checkbox-inner:after {
	background-color: #000080 !important;
}
.tutorial-info {
	font-size: clamp(1rem, calc(0.9rem + 0.4vw), 1.5rem);
	max-width: max-content;
	margin-right: auto;
	margin-left: auto;
}
.tutorial-info .ant-tag {
	font-size: 1em;
	padding: 4px 7px 6px;
}
.tutorial-info p {
	margin-bottom: 0;
}
.custom-radio-group,
.custom-radio-group span.ant-radio+* {
	font-size: clamp(1rem, calc(0.7rem + 0.4vw), 1.5rem);
}
.custom-table-container [class*="col-"]:first-child{
	padding-left: 0 !important;
}
.custom-table-container [class*="col-"]:last-child{
	padding-right: 0 !important;
}
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-title {
	background-color: #E8E8E8;
	font-weight: 700;
	border-bottom-width: 1px;
	padding: 8px 16px;
	 /* min-height: 60px; */
	display: flex;
	align-items: center;
}
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-thead th.ant-table-column-sort,
.grey-custom-table .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: white;
	color: black;
}
.grey-custom-table .ant-table {
	font-size: 16px;
}
.grey-custom-table .ant-table-filter-trigger {
	background-color: #F0F0F0;
	margin-top: -10px !important;
	margin-bottom: -10px !important;
	margin-right: -12px !important;
	font-size: 16px !important;
	color: #8D8D8D !important;
}
.grey-custom-table.custom-tutorial th:first-child, .grey-custom-table.custom-tutorial td:first-child{
	border-right: 0 !important;
}
.grey-custom-table.custom-tutorial .ant-table-thead>tr>th {
	padding: 16px !important;
}
.custom-tutorial td {
	word-break: break-word !important;
}
.title-checkbox {
	padding-right: 24px !important;
}
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-title,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.grey-custom-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-tbody>tr>td {
	border-color: #c3c3c3 !important;
	color: #000000;
}
.grey-custom-table .ant-table-thead>tr>th,
.grey-custom-table .ant-table-tbody>tr>td,
.grey-custom-table .ant-table tfoot>tr>th,
.grey-custom-table .ant-table tfoot>tr>td {
	word-break: break-all;
	line-height: 1.2;
}
.grey-custom-table .ant-table-tbody>tr>td,
.grey-custom-table .ant-table tfoot>tr>th,
.grey-custom-table .ant-table tfoot>tr>td {
	/* padding: 10px 6px !important; */
	padding: 10px 12px !important;
}
.grey-custom-table .ant-table-thead>tr>th {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	height: 40px !important;
}
.grey-custom-table .ant-table-tbody>tr>td {
	height: 40px;
}
/* .grey-custom-table .ant-table-container table > thead > tr > th:first-child,
.grey-custom-table .ant-table-container table > tbody > tr > td:first-child{
border-right: none !important;
} */
/* fullLengthTest */
.full-length-test-container {
	background-color: var(--background-color);
}
/* .full-length-test-container [class*= col-]{
	padding-bottom: 0;
} */
/* .sortable-ghost {
	opacity: .6;
  } */
.drawer-btn.ant-btn{
	font-size: 18px;
	line-height: 1;
}
.ant-btn.timerIcon,
.ant-btn.timerIcon:hover,
.ant-btn.timerIcon:focus,
.ant-btn.timerIcon:active {
	background-color: transparent;
	border: none;
	box-shadow: none !important;
	color: var(--purple3);
	--antd-wave-shadow-color: none !important;
	padding: 0;
	height: auto;
}
/* ul {
	list-style-image: url('../../assets/drag.svg') !important;
	} */
	.drag-icon-size{
		font-size: 30px;
	}
	li.test-sortable p {
		margin-bottom: 0;
	}
.test-sortable li {
	border: 1px solid #d3d3d3;
	padding: 10px;
	border-radius: 30px;
	margin-bottom: 10px;
}
ul.test-sortable {
	list-style-position: inside;
	padding-left: 0;
}
.test-sortable li::marker {
	/* content: url('../../assets/drag.svg') !important; */
	content: '';
}
/* li::marker:active{
	width: 10px !important;
	height: 10px !important;
	color: green;
  } */
.test-dnd-icon {
	color: #000;
    padding: 10px 0 10px 10px;
    font-size: xx-large;
}
.dnd-item {
	gap: 12px;
	display: flex;
}
.dnd-item * {
	margin-bottom: 0;
}
.droppable {
	min-width: 200px !important;
	border-bottom: 1px solid grey;
	margin-right: 0px !important;
	margin-left: 0px !important;
	display: flex;
	justify-content: center;
	align-items: flex-end;
    min-height: 50px;
    position: relative;
    bottom: 10px;
}
.drag-option {
	padding: 10px;
	border-radius: 30px;
}
.drag-option p {
	margin-bottom: 0;
}
.draggable-mr:nth-child(odd){
	margin-right: 2%;
	width: 48% !important;
}
/* notes modal */
/* .notes-modal{
	border: 1px solid #FFEDAE !important;
  } */
.notes-modal .ant-modal-header {
	border-bottom: 2px solid #FFEDAE;
	background-color: #FBF2D5;
}
.notes-modal .ant-modal-body,
.notes-modal .ant-modal-footer {
	background-color: #FEF7E4;
}
.notes-modal::placeholder {
	color: #C0B481;
}
.notes-modal textarea.ant-input {
	background-color: transparent;
	border: none;
	box-shadow: none;
	color: black;
}

/* result-page */
.result-table-container.ant-row > [class*="col-"]:first-child{
	padding-left: 0 !important;
}
.result-table-container.ant-row > [class*="col-"]:last-child{
	padding-right: 0 !important;
}
.result-avatar{
	flex: 0 0 64px;
}

.resut-table td {
	word-break: break-word !important;
}
.resut-table .ant-table-title{
	padding: 0 !important;
	min-height: 60px;
	display: flex;
	align-items: center;
}
.resut-table .table-title p:first-child{
	min-height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.resut-table td > .ant-btn {
	height: auto;
	padding: 0;
	border: 0;
	line-height: normal;
}
.initials-bkg {
	background-color: var(--lightblue);
	width: 50px;
	height: 60px;
	border-radius: 50%;
	color: white;
}
.gold-btn.share-score{
	width: auto;
	bottom: 4px;
}
.ant-btn.share-score .anticon{
	margin-left: 0;
}
.user-initials {
	font-size: 1.5rem;
}
.user-details p {
	margin-bottom: 4px;
	line-height: normal;
}
.user-name-result {
	color: var(--purple3);
}
.test-name,
.user-name-result {
	font-size: 1.2rem;
}
.test-date {
	font-size: 1rem;
}
.test-time {
	font-size: 0.9rem;
}
.score-bkg{
	background-color: #EBEEFF;
}
.score-section{
	padding: 10px 12px;
	font-size: clamp(1.3rem, calc(1rem + 1vw), 1.8rem);
	border: 1px solid #c3c3c3;
	border-bottom: 0;
}
.pass-score{
	color: #00A611;
}
.fail-score{
	color: #8D8D8D;
}
.user-detail-wrapper {
	border-bottom: 2px solid var(--purple3);
	padding-bottom: 20px;
}
.grey-custom-table.resut-table .ant-table .ant-table-title {
	padding: 12px;
	text-align: center;
}
.table-title p {
	padding: 6px 12px;
	margin-bottom: 0;
	text-align: center;
}
.title-two {
	background-color: white;
	margin-bottom: 0;
}

.title-two > .ant-btn {
	height: auto;
	padding: 0;
	border: 0;
	line-height: normal;
}
.result-bottom-section p {
	margin-bottom: 0;
	color: black;
}
.correct-answer {
	color: #000;
}
.avg-time {
	color: #8D8D8D;
}
.score-icon.anticon {
	font-size: 30px;
	color: white;
	z-index: 1;
}
.result {
	font-size: 0.8rem;
	margin-top: 18px;
	text-align: center;
}
.hexagon {
	position: relative;
	width: 50px; 
	height: 28.87px;
	margin: 14.43px 0;
	background-color: var(--purple4);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
  }
.hexagon:before,
.hexagon:after {
	content: "";
	position: absolute;
	width: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
}
.hexagon:before {
	bottom: 100%;
	border-bottom: 14.43px solid var(--purple4);
}
.hexagon:after {
	top: 100%;
	width: 0;
	border-top: 14.43px solid var(--purple4);
}
/* hexagon small */
.hex-sm{width: 31px;height: 15.5px;margin: 8px 0;}
.hex-sm .score-icon{font-size: 17px;}
.hex-sm:before{border-bottom: 10.43px solid var(--purple4);} 
.hex-sm:after{border-top: 10.43px solid var(--purple4);}
.hex-sm:before, .hex-sm:after{border-left: 15px solid transparent; border-right: 15px solid transparent;}
.hex-sm .anticon{
	font-size: 20px;
	z-index: 2;
}
/* hexagon large */
.hex-lg{width: 80px;height: 50px;margin: 8px 0;}
.hex-lg .score-icon{font-size: 40px;}
.hex-lg:before{border-bottom: 20px solid var(--purple4);} 
.hex-lg:after{border-top: 20px solid var(--purple4);}
.hex-lg:before, .hex-lg:after{border-left: 40px solid transparent; border-right: 40px solid transparent;}
/* search result */
.test-question.search-result{
	font-size: 18px;
}
  /* xx */
  /* single cat result page */
.attempts{
	color: var(--purple3);
	font-style: italic;
	font-size: medium;
}
  /* xx */
/* review incorrect */

.ant-radio, .ant-checkbox{
	margin-right: 10px;
}
.review-answers .test-options{
	pointer-events: none;
}
.review-counter {
	counter-reset: alphabeticList;
	pointer-events: none;
}
.pointer-none{
	pointer-events: none;
}
.highlighted
{
		background-color: yellow;
}
.options-counter {
	counter-reset: alphabeticList;
}
.options-inner:before {
	content: counter(alphabeticList,upper-alpha);
	font-weight: 500;
	margin-right: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;

}
.options-inner {
	counter-increment: alphabeticList;
	/* pointer-events: none; */
	display: flex;
  align-items: center;
}
 /* xx */
/* drag and drop counter */
 .radio-grp {
	counter-increment: alphabeticList;
	pointer-events: none;
	display: flex;
  align-items: center;
 }
 .dnd-counter-inner {
	counter-increment: alphabeticList;
	/* pointer-events: none; */
	display: flex;
  align-items: center;
}
 .dnd-counter-inner:before {
	content: counter(alphabeticList,upper-alpha);
	font-weight: 500;
	margin-left: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 10px;
 }
 .dnd-item.dnd-counter-inner {
	 gap: 0;
 }
 /* dnd counter for tests */
 .dnd-counter {
	font-weight: 500;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 20px;
 }
 /* xx */
 /* arrange the seq counter */
 .seq-counter {
	font-weight: 500;
	margin-right: 20px;
	border-right: 2px solid #d9d9d9;
	padding-right: 20px;
	padding-left: 10px;
 }
 .list-item p {
	 margin-bottom: 0;
 }
 /* xx */

.review .ant-radio, .review .ant-radio-inner{
	visibility: hidden;
}
.review.correct-ans .ant-radio, .review.incorrect-ans .ant-radio {
	visibility: inherit;
}
.review.correct-ans .ant-radio:before{
	content: '\2714';
	color: var(--green1);
	font-size: 22px;
	position: absolute;
	top: -6px;
	background-color: white;
	z-index: 1;
}
.review.correct-ans, .review.correct-ans:hover, .review.correct-ans:focus {
	color: var(--green1);
	border: 2px solid var(--green1);
}
.review.incorrect-ans .ant-radio:before{
	content: '\00D7';
	color: var(--red1);
	font-size: 34px;
	position: absolute;
	top: -8px;
	background-color: #fff;
	z-index: 1;
	height: 30px;
	line-height: 1;
}
.review.incorrect-ans, .review.incorrect-ans:hover, .review.incorrect-ans:focus {
	border: 2px solid var(--red1);
	color: var(--red1);
}
.review-test{
	font-size: 18px;
}
.test-options .options.review:not(.correct-ans, .incorrect-ans) {
	color: inherit;
	border: 2px solid #d9d9d9;
}
.ans-display {
	font-size: 14px;
}
/* xx */
.choose-test-card-wrapper .ant-card-body:not(.cat-card .ant-card-body){
	background: #ececec !important;
	padding: 24px 12px;
  }
.cat-card .ant-card-body{text-align: center; padding: 24px 10px;}
.cat-card .ant-card-head-title{
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.type-modal .ant-btn{height: 142px;color: #767732;}
.type-modal .ant-btn:focus,.type-modal .ant-btn:active{background: #E4E893; color: #767732; border-color: #8D8D8D;}

.collapse-notes .ant-collapse-header{background-color: #FBF2D4;border: 1px solid #FFECAE;}
.collapse-notes .ant-collapse-content-box{background-color: #FDF7E3; border: 1px solid #F0F0F0; border-top: none;}

.red-color-time{
	color: red !important;
}
.black-color-time{
	color:black;
}
.custom-placeholder .ant-select-selection-placeholder {
  color: black !important;
}
td.ant-table-cell.td-right span {
	text-align: right;
	width: 55px;
	display: block;
	margin: auto;
}
.space-quit .ant-space-item:last-child{
	margin-left: auto;
}
.mytest-star {
	position: absolute;
	top: 3px;
	left: 18px;
}
.th-center th{
	text-align: center !important;
	}
/* compare score */
.hex-sm.level0{background-color: #F7EFFF; color: white;}
.level0.hex-sm:before {border-bottom: 10.43px solid #F7EFFF;} 
.level0.hex-sm:after{border-top: 10.43px solid #F7EFFF;}

.hex-sm.level1{background-color: var(--green2); color: white;}
.level1.hex-sm:before {border-bottom: 10.43px solid var(--green2);} 
.level1.hex-sm:after{border-top: 10.43px solid var(--green2);}
.hex-sm.level2{background-color: var(--badge2); color: white;}
.level2.hex-sm:before {border-bottom: 10.43px solid var(--badge2);} 
.level2.hex-sm:after{border-top: 10.43px solid var(--badge2);}
.hex-sm.level3{background-color: var(--orange-3); color: white;}
.level3.hex-sm:before {border-bottom: 10.43px solid var(--orange-3);} 
.level3.hex-sm:after{border-top: 10.43px solid var(--orange-3);}
.hex-sm.level4{background-color: var(--purple3); color: white;}
.level4.hex-sm:before {border-bottom: 10.43px solid var(--purple3);} 
.level4.hex-sm:after{border-top: 10.43px solid var(--purple3);}
.hex-sm.level5{background-color: var(--purple2); color: white;}
.level5.hex-sm:before {border-bottom: 10.43px solid var(--purple2);} 
.level5.hex-sm:after{border-top: 10.43px solid var(--purple2);}

.selfcompare-chart svg g g g g rect {
	stroke-width: 1px;
  stroke: #6d6d6d;
	/* display: block; */
}

/* svg g g rect {
	display: none;
} */
.nograph-div{
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 24px;
	color: black;
}
.nograph-div a {
	color: var(--purple3);
}
/* error message on compare score */
div[id*="google-visualization-errors-all-"] *{
	display: none;
}
/* xx */
.ant-btn-link.search-link {
	height: auto;
	display: flex;
	text-align: left;
	white-space: break-spaces;
	gap: 10px;
}
.ant-btn-link.search-link p {
	margin-bottom: 0;
}
/* my test */
.my-test .ant-card-small>.ant-card-head{
	padding: 0px 26px;
}
.my-test .ant-table-pagination.ant-pagination {
	padding: 0px 15px;
}
.mytest-table .ant-table-tbody>tr>td {
	vertical-align: top;
} 
.zoom-btn, .zoom-btn:hover, .zoom-btn:focus {
	background: transparent;
	border: navajowhite;
	font-size: clamp(1.2rem,calc(1.2rem + 1vw),1.7rem);
	padding: 0;
	color: #333333;
}
.zoom-btn.iconDisable, .zoom-btn.iconDisable:hover, .zoom-btn.iconDisable:focus {
	color: lightgray;
	cursor: not-allowed;
}
.gap-table td.result-col{
	padding-right: 25px !important;
}
/* xx */
.test-question table, .test-question th, .test-question td{border: 2px solid #000 !important;}
.purple-border{border: 1px solid var(--purple2);}
.purple-border .zoom-btn, 
.purple-border .zoom-btn:focus, 
.purple-border .zoom-btn:hover{
	font-size: 19px !important;
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}
.purple-border .ant-badge-status{
	line-height: normal !important;
}
.drag-question .ant-space{
	gap: 0 !important;
}
@media screen and (max-width: 991px) {
	.test-options.ant-radio-group .ant-radio-wrapper {
		padding: 10px 18px !important;
	}
}
@media screen and (max-width: 767px) {
	.categoryDetails {
		margin-bottom: 20px;
	}
	.user-details {
		flex: auto;
	}
	.result-btns {
		margin-top: 8px;
	}
}
